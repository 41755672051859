import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements ControlValueAccessor {
  @Input() placeholder!: string;
  @Input() showInvalid = false;

  show = false;
  value: string;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
  }

  private _onTouched = () => {};
  private _onChange = (value: string) => {};

  onValueChange(val: string) {
    if (this._onChange) {
      this._onChange(val);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(data: string): void {
    this.value = data;
  }

  get status() {
    return this.ngControl?.control?.status; // "INVALID", "VALID", "PENDING"
  }

  get invalid(): boolean {
    return this.showInvalid && this.status === 'INVALID';
  }
}
