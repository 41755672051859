import {
  ApprovalRequestList,
  MemoAction,
  NextApprovalPopupState,
  PreviewData,
} from './approval-request.models';

export class ShowMemoDetail {
  static readonly type = '[Approval Request] ShowMemoDetail';
  constructor(public memoId: number, public viaLink?: boolean) {}
}

export class ClearSelectMemo {
  static readonly type = '[Approval Request] ClearSelectMemo';
  constructor() {}
}

export class ToggleMenu {
  static readonly type = '[Approval Request] ToggleMenu';
  constructor() {}
}

export class ShowDecisionPopup {
  static readonly type = '[Approval Request] ShowDecisionPopup';
  constructor(public action?: MemoAction) {}
}

export class SaveComment {
  static readonly type = '[Approval Request] SaveComment';
  constructor(public comment: string) {}
}

export class SaveOTP {
  static readonly type = '[Approval Request] SaveOTP';
  constructor(public otp: string) {}
}

export class SaveReason {
  static readonly type = '[Approval Request] SaveReason';
  constructor(public reason: string) {}
}

export class SaveSignature {
  static readonly type = '[Approval Request] SaveSignature';
  constructor(public signature: any) {}
}

export class SaveConfirm {
  static readonly type = '[Approval Request] SaveConfirm';
  constructor() {}
}

export class CancelDecisionPopup {
  static readonly type = '[Approval Request] CancelDecisionPopup';
  constructor() {}
}

export class ClearState {
  static readonly type = '[Approval Request] ClearState';
  constructor() {}
}

export class Approve {
  static readonly type = '[Approval Request] Approve';
  constructor() {}
}

export class Reject {
  static readonly type = '[Approval Request] Reject';
  constructor() {}
}

export class Terminate {
  static readonly type = '[Approval Request] Terminate';
  constructor() {}
}

export class MultiApprove {
  static readonly type = '[Approval Request] MultiApprove';
  constructor() {}
}

export class MultiReject {
  static readonly type = '[Approval Request] MultiReject';
  constructor() {}
}

export class MultiTerminate {
  static readonly type = '[Approval Request] MultiTerminate';
  constructor() {}
}

export class NextApprovalPopup {
  static readonly type = '[Approval Request] NextApprovalPopup';
  constructor(public state: NextApprovalPopupState | null) {}
}

export class ShowPreviewData {
  static readonly type = '[Approval Request] ShowPreviewData';
  constructor(public payload: PreviewData) {}
}

export class ClosePreviewData {
  static readonly type = '[Approval Request] ClosePreviewData';
  constructor() {}
}

export class UpdateMultiMemos {
  static readonly type = '[Approval Request] UpdateMultiMemos';
  constructor(public payload: ApprovalRequestList[]) {}
}

export class EmptyContent {
  static readonly type = '[Approval Request] EmptyContent';
  constructor(public emptyContent?: boolean) {}
}
