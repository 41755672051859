import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { ErrorNotification } from '../../../store/memo/memo.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  token: string;

  mode: 'form' | 'success' = 'form';

  errors: { [key: string]: string } = {};
  isLoading = false;

  subscription: Subscription[] = [];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
  ) {
    const sub = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.token = params['id'];
      },
    );
    this.subscription.push(sub);
  }

  ngOnInit() {
    this.checkToken();
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  checkToken() {
    const sub = this.authenticationService
      .validateResetPasswordToken({ token: this.token })
      .subscribe(
        () => {},
        () => {
          this.router.navigate(['/expire']).then();
        },
      );
    this.subscription.push(sub);
  }

  send(password: string) {
    this.isLoading = true;
    const sub = this.authenticationService
      .resetPassword({
        password: password,
        token: this.token,
      })
      .subscribe(
        () => {
          this.mode = 'success';
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 400) {
            this.errors = error.error;
          } else {
            this.store.dispatch(new ErrorNotification(error));
          }
        },
      );
    this.subscription.push(sub);
  }

  login() {
    this.router.navigate(['/login']);
  }
}
