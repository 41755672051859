import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { PasswordPolicy } from '../../setting/component/theme/component/password-policy/password-policy.model';
import { TranslateService } from '@ngx-translate/core';
import {
  passwordMatchValidator,
  passwordValidator,
} from '@shared/utils/validator';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-password-form',
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.scss'],
})
export class NewPasswordFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() isLoading = false;
  @Input() errors: { [key: string]: string } = {};

  @Output() next = new EventEmitter<string>();

  form: UntypedFormGroup;

  passwordPolicy: PasswordPolicy;
  showPasswordRuleBox = false;

  subscription: Subscription[] = [];

  constructor(
    private builder: UntypedFormBuilder,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.getPasswordPolicy();
    this.form = this.builder.group({
      confirm_password: [null, [Validators.required]],
      password: [
        null,
        [
          Validators.required,
          this.backendErrorValidation('password'),
        ],
      ],
      valid_password: [false, [Validators.required]],
    });
    this.f.confirm_password.addValidators(
      passwordMatchValidator(this.f?.password),
    );
    this.f.valid_password.addValidators(
      passwordValidator(this.f?.password),
    );
  }

  ngOnChanges(changes: { [input: string]: SimpleChange }) {
    if (changes['errors']) {
      this.updateValidation();
    }
  }

  getPasswordPolicy(): void {
    const sub = this.authenticationService
      .getPasswordPolicy()
      .subscribe((res: any) => {
        this.passwordPolicy = res;
      });
    this.subscription.push(sub);
  }

  backendErrorValidation(key: string): ValidatorFn {
    return (
      control: AbstractControl,
    ): { [key: string]: string } | null => {
      return this.errors[key] ? { be_error: this.errors[key] } : null;
    };
  }

  setValidPassword(valid: boolean) {
    this.f.valid_password.setValue(valid);
  }

  updateValidation() {
    if (this.form) {
      this.f.password.updateValueAndValidity();
      this.f.valid_password.updateValueAndValidity();
    }
  }

  send() {
    this.errors = {};
    this.updateValidation();
    if (this.form.valid) {
      this.next.emit(this.f.password.value);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } | any {
    return this.form.controls;
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
