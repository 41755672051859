import { createSelector, PropertySelectors } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { SelectorDef } from '@ngxs/store/src/selectors';
import * as _ from 'lodash';

export function createDeepPropSelector<
  TModel = { [key: string]: any },
>(parentSelector: SelectorDef<TModel>): PropertySelectors<TModel> {
  const cache: Partial<PropertySelectors<TModel>> = {};
  return new Proxy<PropertySelectors<TModel>>(
    {} as unknown as PropertySelectors<TModel>,
    {
      get(_target: any, prop: keyof TModel) {
        const selector =
          cache[prop] ||
          (createSelector([parentSelector], (s: TModel) =>
            _.get(s, prop),
          ) as PropertySelectors<TModel>[typeof prop]);
        cache[prop] = selector;
        return selector;
      },
    } as ProxyHandler<PropertySelectors<TModel>>,
  );
}

export function getDeepPatch(
  path: string | string[],
  value: any,
): { [k: string]: any } {
  const pathArr = Array.isArray(path) ? path : path.split('.');
  if (pathArr.length < 1) {
    throw new Error('path cannot be empty');
  }
  const isArray = Array.isArray(value);
  const isNotObj = !value || typeof value !== 'object' || isArray;
  return pathArr.reduceRight(
    (resultObj, key) => {
      const index = key.match(/^\d+$/)?.[0];
      return index != null
        ? updateItem(Number(index), resultObj)
        : patch({ [key]: resultObj });
    },
    isNotObj || isArray ? value : patch(value),
  );
}
