<span class="input d-flex align-items-center">
  <input class="number-input"
      type="number"
      [min]="min"
      [max]="max"
      [(ngModel)]="model"
      (ngModelChange)="emit()">
  <div *ngIf="deviceType === 'desktop'"
      class="spinners">
    <svg class="pointer"
        height="10"
        stroke="currentColor"
        stroke-width="1.5"
        width="10"
        (click)="increment()">
      <use height="10"
          width="10"
          href="assets/images/icons/arrows/arrow-up.svg#arrow-up">
      </use>
    </svg>
    <svg class="pointer"
        height="10"
        stroke-width="1.5"
        stroke="currentColor"
        width="10"
        (click)="decrement()">
      <use height="10"
          width="10"
          href="assets/images/icons/arrows/arrow-down.svg#arrow-down">
      </use>
    </svg>
  </div>
  <div *ngIf="deviceType !== 'desktop'"
      class="spinners-left">
    <svg class="pointer"
        height="10"
        stroke="currentColor"
        width="10"
        (click)="decrement()">
      <use href="assets/images/icons/arrows/arrow-down.svg#arrow-down">
      </use>
    </svg>
  </div>
  <div *ngIf="deviceType !== 'desktop'"
      class="spinners-right">
    <svg class="pointer"
        height="10"
        stroke="currentColor"
        width="10"
        (click)="increment()">
      <use href="assets/images/icons/arrows/arrow-up.svg#arrow-up">
      </use>
    </svg>
  </div>
</span>