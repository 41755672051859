/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import {
  NgbDateStruct,
  NgbDatepickerI18n,
} from '@ng-bootstrap/ng-bootstrap';
import localeThai from '@angular/common/locales/th';
import {
  getLocaleDayNames,
  FormStyle,
  TranslationWidth,
  getLocaleMonthNames,
  formatDate,
  registerLocaleData,
} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
const I18N_VALUES_TH = {
  th: {
    weekday: ['จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.', 'อา.'],
    months: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
  },
};
const I18N_VALUES_EN = {
  en: {
    weekday: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
};

@Injectable()
export class NgbDatepickerI18nBuddhist extends NgbDatepickerI18n {
  private _weekdaysShort: readonly string[];
  private _monthsShort: readonly string[];
  private _monthsFull: readonly string[];
  private _dayshortName: readonly string[];
  constructor(private translate: TranslateService) {
    super();

    registerLocaleData(localeThai);

    const weekdaysStartingOnSunday = getLocaleDayNames(
      this.translate.currentLang,
      FormStyle.Standalone,
      TranslationWidth.Short,
    );
    this._weekdaysShort = weekdaysStartingOnSunday.map(
      (day, index) => weekdaysStartingOnSunday[(index + 1) % 7],
    );

    this._monthsShort = getLocaleMonthNames(
      this.translate.currentLang,
      FormStyle.Standalone,
      TranslationWidth.Abbreviated,
    );
    this._monthsFull = getLocaleMonthNames(
      this.translate.currentLang,
      FormStyle.Standalone,
      TranslationWidth.Wide,
    );
    this._dayshortName = getLocaleDayNames(
      this.translate.currentLang,
      FormStyle.Standalone,
      TranslationWidth.Wide,
    );
  }
  getWeekdayShortName(day: number): string {
    return this._dayshortName[day] || '';
  }
  getMonthShortName(month: number): string {
    return this.translate.currentLang === 'en'
      ? I18N_VALUES_EN.en.months[month - 1] || ''
      : I18N_VALUES_TH.th.months[month - 1] || '';
  }

  getMonthFullName(month: number): string {
    return this._monthsFull[month - 1] || '';
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth) {
    return this.translate.currentLang === 'en'
      ? I18N_VALUES_EN.en.weekday[weekday - 1] || ''
      : I18N_VALUES_TH.th.weekday[weekday - 1] || '';
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return formatDate(jsDate, 'fullDate', this.translate.currentLang);
  }

  //@ts-ignore
  getYearNumerals(year: number): string {
    if (this.translate.currentLang === 'th' && year < 2300) {
      year += 543;
    }
    return String(year);
  }
}
