<div class="my-3 box-validate-password"
    *ngIf="passwordPolicy">
  <div class="fs-base-l2 fw-medium text-black-pantone">
    {{ passwordStatus | translate }}
  </div>
  <div class="my-1 fs-base-l4 text-gray-pantone">
    {{ 'VALIDATION.Your password must contained' | translate }} :
  </div>
  <ng-container *ngFor="let rule of validateRules">
    <div class="text-asterisk mb-0 d-flex align-items-center"
        *ngIf="passwordPolicy[rule.key]"
        [ngClass]="{ 'text-green': rule.valid }">
      <svg class="me-2 mb-1"
          width="24"
          height="24"
          stroke="currentColor">
        <use [attr.href]="'assets/images/icons/' + getIconPath(rule.valid)"></use>
      </svg>
      <span *ngIf="rule.key === 'minimum_length'"
          class="fs-base-l2 mb-1">
        {{ 'VALIDATION.LENGTH-CHARACTER' | translate }}
        {{ passwordPolicy.minimum_length }}
        {{ 'VALIDATION.characters'|translate }}
      </span>
      <span *ngIf="rule.key !== 'minimum_length'"
          class="fs-base-l2 mb-1">{{ rule.text | translate }}</span>
    </div>
  </ng-container>
</div>