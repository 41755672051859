import { Action, State, StateContext } from '@ngxs/store';
import { OTP } from './otp.model';
import { Injectable } from '@angular/core';
import { SetRef } from './otp.actions';

@State<OTP>({
  name: 'otp',
  defaults: {
    ref: '',
    refDisplay: '',
  },
})
@Injectable({
  providedIn: 'root',
})
export class OtpState {
  @Action(SetRef)
  setRef({ setState }: StateContext<OTP>, { payload }: any): void {
    setState({
      ...payload,
    });
  }
}
