<div class="w-100 h-100 d-flex align-items-center justify-content-center">
  <div class="content-box">
    <div *ngIf="iconType === 'img'">
      <img width="250"
          [src]="iconPath">
    </div>
    <div *ngIf="iconType === 'svg'">
      <svg height="70"
          width="200">
        <use href="assets/images/pictograms/paint.svg#paint"
            height="70"
            fill="#EAEAED">
        </use>
        <use [attr.href]="iconPath"
            height="70"
            width="200">
        </use>
      </svg>
    </div>
    <div class="text-center">
      <div class="fw-medium fs-l0">{{ title | translate}}</div>
      <div class="text-gray-neutrals fs-l4">{{ subtitle |translate}}</div>
    </div>
    <div *ngIf="goBackButton"
        class="text-center">
      <button class="btn btn-std-black-pantone"
          (click)="onClick()">
        {{ "PERMISSION.Go back" | translate }}
      </button>
    </div>
  </div>
</div>