import { Injectable } from '@angular/core';
import { ApiService } from '@core/http/api.service';
import { Observable } from 'rxjs';
import { SavedDashboard } from '@core/components/dashboard-container/dashboard-container.constant';

@Injectable({
  providedIn: 'root',
})
export class SavedDashboardService {
  constructor(private http: ApiService) {}

  getSavedDashboard(): Observable<SavedDashboard> {
    return this.http.get<SavedDashboard>('/api/saved-dashboard/');
  }
}
