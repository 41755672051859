import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { featureFlag } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any> | any,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('theme-config-active') ||
      (!featureFlag.media_url_need_authentication &&
        request.url.includes('AWSAccessKeyId=') &&
        request.url.includes('Signature='))
    ) {
      // eslint-disable-next-line max-len
      // sample s3 url: https://s3.ap-southeast-1.wasabisys.com/memo-std-dev/1/person_preview_signatures/0e257e1a-a11e-4e56-a245-7c8e6193fe78.png?AWSAccessKeyId=AO2IJFHU5VG233CQ30RH&Signature=W1tO1DWDzUG8QjQrr5rMP8sn20A%3D&Expires=1711354340&responseType=blob
      // s3, wasabi, minio will have authoriation token in url. no need to add JWT from memo server.
      return next.handle(request);
    }

    // add auth header with jwt if user is logged in and request is to the api url
    const isLoggedIn = localStorage.getItem('currentUser');
    if (isLoggedIn && !request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem(
            'currentUser',
          )}`,
        },
      });
      // Pass the cloned request instead of the original request to the next handle
    }

    if (
      localStorage.getItem('JWTKey') &&
      this.router.url.includes('/approval/')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: localStorage.getItem('JWTKey'),
        },
      });
    }
    return next.handle(request);
  }
}
