/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ExtendedNgbDatepickerConfig extends NgbDatepickerConfig {
  //@ts-ignore
  minDate = { year: 1980, month: 1, day: 1 };
  //@ts-ignore
  maxDate = {
    year:
      new Date().getFullYear() +
      20 +
      (localStorage.getItem('lang') === 'th' ? 543 : 0),
    month: 1,
    day: 1,
  };
}
