/* eslint-disable */

import { GroundColor, LanguageDisplay } from '@shared/models/common.model';

export const QUOTE_EN = [
  "Welcome! Let's get your document signed.",
  "Ready to sign? Let's get started!",
  'Hello! Time to finalize your document.',
  "Ready to seal the deal? Let's go!",
  "Ready to sign? We're here to help.",
  "Let's complete this with your signature.",
  "Ready to sign and send? Let's begin.",
  "Hello! Let's get that document signed.",
  'Welcome! Time to add your signature.',
  "Ready to make it official? Let's start.",
  "Let's get your document signed quickly and easily.",
  "Ready to sign your document? We're here to assist.",
  "Welcome! Let's finalize your document.",
  "Let's complete your document with a signature.",
  "Ready to sign? We'll guide you through.",
  'Welcome! Time to put your signature on it.',
  "Ready to make it official? We're here to help.",
  "Let's get your document signed in no time.",
  "Ready to sign? Let's make it quick and easy.",
  "Welcome! Let's finish this with your signature.",
  "Ready to add your signature? Let's go.",
  "Let's make your document official with a signature.",
  "Ready to sign? We're just a few clicks away.",
  "Welcome! Let's get your signature on the document.",
  "Ready to sign and send? We're here to help.",
  'Welcome! Time to sign your document.',
  "Let's get your document signed and sealed.",
  "Ready to sign? Let's get it done.",
  "Welcome! Let's finalize your document with a signature.",
  "Ready to add your signature? We're here to assist.",
  "Let's make it official with your signature.",
  "Ready to sign? Let's get started now.",
  "Welcome! Let's get your signature on the line.",
  "Ready to sign? We're here to make it easy.",
  "Let's complete this document with your signature.",
  "Ready to make it official? Let's begin.",
  'Welcome! Time to add your signature.',
  "Ready to sign? Let's make it quick.",
  "Let's get your document signed smoothly.",
  "Ready to sign? We'll guide you through.",
  "Welcome! Let's finalize your document.",
  "Let's complete your document with a signature.",
  "Ready to sign? We're here to help.",
  'Welcome! Time to put your signature on it.',
  "Ready to make it official? Let's start.",
  "Let's get your document signed in no time.",
];

export const QUOTE_TH = [
  'ยินดีต้อนรับ! มาเซ็นเอกสารของคุณกันเถอะ',
  'พร้อมเซ็นหรือยัง? มาเริ่มกันเลย!',
  'สวัสดี! ได้เวลาเซ็นเอกสารแล้ว',
  'พร้อมทำข้อตกลงหรือยัง? ลุยเลย!',
  'พร้อมเซ็นหรือยัง? เราพร้อมช่วยคุณ',
  'มาเสร็จสิ้นเอกสารนี้ด้วยลายเซ็นของคุณกัน',
  'พร้อมเซ็นและส่งไหม? เริ่มกันเถอะ',
  'สวัสดี! มาเซ็นเอกสารนี้กัน',
  'ยินดีต้อนรับ! ได้เวลาเพิ่มลายเซ็นของคุณแล้ว',
  'พร้อมทำให้เป็นทางการหรือยัง? มาเริ่มกันเลย',
  'มาเซ็นเอกสารของคุณอย่างรวดเร็วและง่ายดายกันเถอะ',
  'พร้อมเซ็นเอกสารของคุณหรือยัง? เราพร้อมช่วยเหลือ',
  'ยินดีต้อนรับ! มาทำให้เอกสารของคุณเสร็จสมบูรณ์กัน',
  'มาเซ็นเอกสารของคุณกันเถอะ',
  'พร้อมเซ็นหรือยัง? เราจะคอยชี้แนะคุณ',
  'ยินดีต้อนรับ! ได้เวลาใส่ลายเซ็นของคุณแล้ว',
  'พร้อมทำให้เป็นทางการหรือยัง? เราพร้อมช่วยคุณ',
  'มาเซ็นเอกสารของคุณในเวลาไม่นานกันเถอะ',
  'พร้อมเซ็นหรือยัง? มาให้เร็วและง่ายกันเถอะ',
  'ยินดีต้อนรับ! มาเสร็จสิ้นเอกสารนี้ด้วยลายเซ็นของคุณกัน',
  'พร้อมเพิ่มลายเซ็นของคุณหรือยัง? มาเริ่มกันเถอะ',
  'มาเซ็นเอกสารของคุณให้เป็นทางการกันเถอะ',
  'พร้อมเซ็นหรือยัง? เราอยู่ใกล้แค่ไม่กี่คลิก',
  'ยินดีต้อนรับ! มาเซ็นเอกสารนี้กันเถอะ',
  'พร้อมเซ็นและส่งไหม? เราพร้อมช่วยคุณ',
  'ยินดีต้อนรับ! ได้เวลาเซ็นเอกสารของคุณแล้ว',
  'มาเซ็นและประทับตราเอกสารของคุณกันเถอะ',
  'พร้อมเซ็นหรือยัง? มาเริ่มกันเลย',
  'ยินดีต้อนรับ! มาเสร็จสิ้นเอกสารนี้ด้วยลายเซ็นของคุณกัน',
  'พร้อมเพิ่มลายเซ็นของคุณหรือยัง? เราพร้อมช่วยเหลือ',
  'ทำให้เป็นทางการด้วยลายเซ็นของคุณกันเถอะ',
  'พร้อมเซ็นหรือยัง? มาเริ่มกันเลยตอนนี้',
  'ยินดีต้อนรับ! มาใส่ลายเซ็นของคุณบนเอกสารกันเถอะ',
  'พร้อมเซ็นหรือยัง? เราพร้อมทำให้ง่าย',
  'มาเสร็จสิ้นเอกสารนี้ด้วยลายเซ็นของคุณกันเถอะ',
  'พร้อมทำให้เป็นทางการหรือยัง? มาเริ่มกันเลย',
  'ยินดีต้อนรับ! ได้เวลาเพิ่มลายเซ็นของคุณแล้ว',
  'พร้อมเซ็นหรือยัง? มาให้รวดเร็วกันเถอะ',
  'มาเซ็นเอกสารของคุณอย่างราบรื่นกันเถอะ',
  'พร้อมเซ็นหรือยัง? เราจะคอยชี้แนะคุณ',
  'ยินดีต้อนรับ! มาเสร็จสิ้นเอกสารของคุณกัน',
  'มาเซ็นเอกสารของคุณกันเถอะ',
  'พร้อมเซ็นหรือยัง? เราพร้อมช่วยคุณ',
  'ยินดีต้อนรับ! ได้เวลาใส่ลายเซ็นของคุณแล้ว',
  'พร้อมทำให้เป็นทางการหรือยัง? มาเริ่มกันเลย',
  'มาเซ็นเอกสารของคุณในเวลาไม่นานกันเถอะ',
];

export const DBItems = {
  Paper: 'paper',
  Cose: 'cost',
  Water: 'water',
  Carbon: 'carbon',
  Chlorine: 'chlorine',
  Electric: 'electric',
  Oil: 'oil',
  Tree: 'tree'
} as const;

export const DBItemColors: {
  [k in DBItem]?: GroundColor;
} = {
  paper: {
    foreground: '#E8E8C7',
    background: '#EBE9B536',
  },
  cost: {
    foreground: '#DBE8D6',
    background: '#C3EBB536',
  },
  water: {
    foreground: '#D1E6E6',
    background: '#B5E8EB36',
  },
  carbon: {
    foreground: '#DBDBDB',
    background: '#D2D2D236',
  },
  chlorine: {
    foreground: '#C9E1EF',
    background: '#B5D7EB36',
  },
  electric: {
    foreground: '#E8D2F1',
    background: '#E8C6F836',
  },
  oil: {
    foreground: '#EDE0C5',
    background: '#FFE6A536',
  },
  tree: {
    foreground: '#C7E4BE',
    background: '#9CDB8C36',
  },
} as const;

export const DBItemIconPaths: {
  [k in DBItem]: string;
} = {
  paper: 'assets/images/icons/saved-db/paper.svg#paper',
  cost: 'assets/images/icons/saved-db/cost.svg#cost',
  water: 'assets/images/icons/saved-db/water.svg#water',
  carbon: 'assets/images/icons/saved-db/carbon.svg#carbon',
  chlorine: 'assets/images/icons/saved-db/chlorine.svg#chlorine',
  electric: 'assets/images/icons/saved-db/electric.svg#electric',
  oil: 'assets/images/icons/saved-db/oil.svg#oil',
  tree: 'assets/images/icons/saved-db/tree.svg#tree',
};

export const AnimationVidPaths = {
  50: 'assets/video/ns1-1920x1080.mp4',
  100: 'assets/video/ns2-1920x1080.mp4',
  300: 'assets/video/ns3-1920x1080.mp4',
  500: 'assets/video/ns4-1920x1080.mp4',
  1000: 'assets/video/ns5-1920x1080.mp4',
  3000: 'assets/video/ns6-1920x1080.mp4',
  5000: 'assets/video/ns7-1920x1080.mp4',
  10000: 'assets/video/ns8-1920x1080.mp4',
}

export type DBItem =
  (typeof DBItems)[keyof typeof DBItems];

export interface SavedDashboardValue {
  value: string,
  unit: LanguageDisplay<string>,
}

export type SavedDashboard = {
  [k in (DBItem | 'last_month_tree' | 'page_count')]: SavedDashboardValue;
};