import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ModalConfirmationStd4SkinComponent } from '@shared/components/modal-confirmation-std4-skin/modal-confirmation-std4-skin.component';
import { Logout } from 'src/app/store/auth/auth.actions';

@Component({
  selector: 'app-log-out-modal',
  templateUrl: './log-out-modal.component.html',
  styleUrls: ['./log-out-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ModalConfirmationStd4SkinComponent,
    FormsModule,
  ],
})
export class LogOutModalComponent {
  private store = inject(Store);
  private activeModal = inject(NgbActiveModal);

  logOut() {
    this.store.dispatch(Logout);
    this.activeModal.close();
  }

  dismissModal() {
    this.activeModal.dismiss();
  }
}
