import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Params } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-svg-page-placeholder',
  templateUrl: './svg-page-placeholder.component.html',
  styleUrls: ['./svg-page-placeholder.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgPagePlaceholderComponent implements OnChanges {
  @Input() backgroundImage?:
    | string
    | { [attr: string]: number | string };
  @Input() height = 80;
  @Input() standImage?: string | { [attr: string]: number | string };
  @Input() subTitle?: string;
  @Input() width = 250;
  @Input() title?: string;
  @Input() viewBox? = '';
  @Input() customSvgClass?: string;

  // TODO: Improve to able to set all attributes
  backgroundImageAttributes: Params = {
    width: 250,
    height: 80,
    src: 'assets/images/pictograms/paint.svg#paint',
    x: 0,
    y: 0,
    fill: '#EAEAED',
  };
  // TODO: Improve to able to set all attributes
  standImageAttributes: Params = {
    width: 84,
    height: 74,
    x: 83,
    y: 3,
  };

  private utils = inject(UtilsService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['standImage']) {
      const standImage = changes['standImage'].currentValue as
        | string
        | { [attr: string]: number | string };
      if (typeof standImage === 'string') {
        this.standImageAttributes = {
          ...this.standImageAttributes,
          src: standImage,
        };
      } else {
        this.standImageAttributes = {
          ...this.standImageAttributes,
          ...standImage,
        };
      }
      if (this.standImageAttributes['src']) {
        this.standImageAttributes['src'] =
          this.utils.appendTagFromSvgPath(
            this.standImageAttributes['src'],
          );
      }
    }
    if (changes['backgroundImage']) {
      const backgroundImage = changes['backgroundImage']
        .currentValue as string | { [attr: string]: number | string };
      if (typeof backgroundImage === 'string') {
        this.backgroundImageAttributes = {
          ...this.backgroundImageAttributes,
          src: backgroundImage,
        };
      } else {
        this.backgroundImageAttributes = {
          ...this.backgroundImageAttributes,
          ...backgroundImage,
        };
      }
      if (this.backgroundImageAttributes['src']) {
        this.backgroundImageAttributes['src'] =
          this.utils.appendTagFromSvgPath(
            this.backgroundImageAttributes['src'],
          );
      }
    }
  }
}
