import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/http/api.service';
import { featureFlag } from '../../../environments/environment';

@Pipe({
  name: 'secure',
  standalone: true,
})
export class SecurePipe implements PipeTransform {
  constructor(
    private http: ApiService,
    private sanitizer: DomSanitizer,
  ) {}

  isValidHttpUrl(text: string): boolean {
    let url;
    try {
      url = new URL(text);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  transform(url: string | any, defaultImage: any = true): any {
    if (!url) {
      return null;
    }
    if (!this.isValidHttpUrl(url)) {
      return new Observable<string>((observer) => {
        observer.next(url);
      });
    }

    return new Observable<string>((observer) => {
      // This is a tiny blank image
      if (defaultImage) {
        observer.next(
          'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
        );
      }

      // The next and error callbacks from the observer
      const { next, error } = observer;

      if (
        !featureFlag.media_url_need_authentication &&
        url.includes('AWSAccessKeyId=') &&
        url.includes('Signature=')
      ) {
        // eslint-disable-next-line max-len
        // sample s3 url: https://s3.ap-southeast-1.wasabisys.com/memo-std-dev/1/person_preview_signatures/0e257e1a-a11e-4e56-a245-7c8e6193fe78.png?AWSAccessKeyId=AO2IJFHU5VG233CQ30RH&Signature=W1tO1DWDzUG8QjQrr5rMP8sn20A%3D&Expires=1711354340&responseType=blob
        // s3, wasabi url can request directly without add headers.
        observer.next(url);
      } else {
        this.http
          .getMedia(url, { responseType: 'blob' })
          .subscribe((response) => {
            const reader = new FileReader();
            reader.readAsDataURL(response as Blob);
            reader.onloadend = () => {
              observer.next(reader.result as string);
            };
          });
      }

      return {
        unsubscribe() {},
      };
    });
  }
}
