export const LOOP_COLOR_LIST = [
  '#007AFF',
  '#AF52DE',
  '#34C759',
  '#FF2D55',
  '#5AC8FA',
  '#FFCC00',
  '#FF9500',
  '#1958D3',
  '#2A3049',
  '#6D00A8',
  '#B48F64',
] as const;
