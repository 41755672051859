import { UserPayload } from './users.model';

export class GetUsers {
  static readonly type = '[Users] Get Users';
  constructor(
    public search: any,
    public page: string,
    public role?: string,
  ) {}
}
export class AddUsers {
  static readonly type = '[Users] Add Users';
  constructor(public payload: UserPayload) {}
}
export class UpdateUsers {
  static readonly type = '[Users] Update Users';
  constructor(public id: number, public payload: UserPayload) {}
}
export class DeleteUsers {
  static readonly type = '[Users] Delete Users';
  constructor(public id: number, public payload: UserPayload) {}
}
export class SelectedUser {
  static readonly type = '[Users] Select Users';
  constructor(public id: string) {}
}
