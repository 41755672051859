import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Primitive } from '@shared/models/common.model';
import { nanoid } from 'nanoid';

export function base64ToFile(data: any, filename = 'photo.png') {
  const arr = data.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function convertFile(blob: Blob, filename = 'photo.png') {
  return new File([blob], filename, {
    type: blob?.type,
  });
}

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  appendTagFromSvgPath(path: string): string {
    const pathMatch = path?.match(
      /(?:(?:\/?.+\/(.+))|(?:\/?(.+)))\.svg$/,
    );
    const fragment = pathMatch?.[1] || pathMatch?.[2];
    if (fragment) {
      return path + '#' + fragment;
    } else {
      return path;
    }
  }

  createNanoId(digits = 12): string {
    return nanoid(digits);
  }

  getNextPageFromDrfList(
    nextPageUrl: string | undefined | null,
  ): number | null {
    return nextPageUrl == null
      ? null
      : Number(nextPageUrl.match(/(?:\?|&)page=(\d+)/)?.[1]) || null;
  }

  /**
   * Retrieves valid query parameters from a given params.
   * It can retrieve nested values in the params object
   * by uses the `objAccessKey` property from the `options` parameter.
   * It then processes the values to ensure they are primitive types and
   * returns an object containing the valid query parameters.
   *
   * @param params - the parameters object
   * @param options - the options object with objAccessKey property
   * @param options.objAccessKey - the key used to access nested values in the params object
   *
   * @example
   *  const params = {
        name: 'my name',
        birthDay: [1990, 01, 25],
        address: {
          city: 'my city',
          value: [
            { name: 'my country', value: 'country_value' },
            { name: 'my country 1', value: () => 'country_value_1' }
          ],
        }
        spouse: null,
        children: undefined,
        car_count: () => Math.random(),
      }

      // results (if nullable is true)
      const validParams = {
          name: 'my name',
          birthDay: '1990|01|25',
          address: 'country_value|country_value_1',
          spouse: 'null',
          car_count: 0.006901030942387365,
      }
   */
  getValidQueryParams(
    params: Params,
    options?: Partial<GetValidQueryParamsOptions>,
  ): { [k: string]: Primitive } {
    const nullable = options?.nullable || false;
    const separator = options?.separator || '|';
    const objAccessKey = options?.objAccessKey || 'value';
    const retrievePrimitiveValue = (
      val: any,
    ): Primitive | undefined => {
      if (val == null) {
        return nullable && val === null ? 'null' : undefined;
      } else if (this.isPrimitiveType(val)) {
        return val;
      } else if (Array.isArray(val)) {
        let serializedArr = '';
        (val as any[]).forEach((item) => {
          let append: Primitive | undefined;
          if (this.isPrimitiveType(item)) {
            append = String(item);
          } else {
            const serialized = retrievePrimitiveValue(item);
            if (serialized != null) {
              append = serialized;
            }
          }
          if (append) {
            serializedArr +=
              (serializedArr !== '' ? separator : '') + append;
          }
        });
        return serializedArr;
      } else {
        let nestedValue: any;
        if (typeof val === 'object') {
          nestedValue = val[objAccessKey];
        } else if (typeof val === 'function') {
          nestedValue = val();
        }
        if (this.isPrimitiveType(nestedValue)) {
          return nestedValue as Primitive;
        }
        return retrievePrimitiveValue(nestedValue);
      }
    };
    return Object.keys(params).reduce((obj, key) => {
      const value = params[key];
      const primitiveVal = retrievePrimitiveValue(value);
      if (primitiveVal != null) {
        obj[key] = primitiveVal;
      }
      return obj;
    }, {} as { [k: string]: Primitive });
  }

  isPrimitiveType(value: any): boolean {
    return ['boolean', 'number', 'string'].includes(typeof value);
  }

  isSameMemberArray(a: Primitive[], b: Primitive[]): boolean {
    if (a.length !== b.length) {
      return false;
    }
    const obj: { [k: string]: number } = {};
    a.forEach((member) => {
      const key = String(member);
      obj[key] = (obj[key] || 0) + 1;
    });
    b.forEach((member) => {
      const key = String(member);
      obj[key]--;
    });
    return Object.values(obj).every((val) => val === 0);
  }
}

export interface GetValidQueryParamsOptions {
  objAccessKey: string;
  separator: string;
  nullable: boolean;
}
