import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { CanvasWhiteboardModule } from '@preeco-privacy/ng2-canvas-whiteboard';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SetPasswordComponent } from './set-password/set-password.component';
import { HammerModule } from '@angular/platform-browser';
import { ImageProcessingService } from 'src/app/shared/service/image-processing.service';
import { PasswordRuleBoxComponent } from '@shared/components/pasword-rule-box/password-rule-box.component';
import { SvgPagePlaceholderComponent } from '@shared/components/svg-page-placeholder/svg-page-placeholder.component';
import { NewPasswordFormComponent } from './new-password-form/new-password-form.component';
@NgModule({
  declarations: [
    ResetPasswordComponent,
    SetPasswordComponent,
    NewPasswordFormComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ResetPasswordRoutingModule,
    ReactiveFormsModule,
    ColorPickerModule,
    CanvasWhiteboardModule,
    ImageCropperModule,
    SharedModule,
    HammerModule,
    PasswordRuleBoxComponent,
    SvgPagePlaceholderComponent,
  ],
  providers: [ImageProcessingService],
  exports: [ResetPasswordComponent, SetPasswordComponent],
})
export class ResetPasswordModule {}
