<div class="container content-frame d-flex align-items-center justify-content-center">
  <div class="w-100">
    <app-memo-no-permission
        [iconType]="noPermissionDetail.iconType"
        [iconPath]="noPermissionDetail.iconPath"
        [title]="noPermissionDetail.title"
        [subtitle]="noPermissionDetail.subtitle"
    ></app-memo-no-permission>
    <div *ngIf="!memo_status" class="text-center mt-4">
      <button class="btn btn-std-black-pantone"
          [routerLink]="['/login']">
        Go back
      </button>
    </div>
  </div>
</div>