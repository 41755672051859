import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PasswordPolicy } from '../../../modules/setting/component/theme/component/password-policy/password-policy.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

interface validateRule {
  key: keyof Pick<
    PasswordPolicy,
    | 'is_needed_lower_case'
    | 'is_needed_upper_case'
    | 'is_needed_digit'
    | 'is_needed_special_case'
    | 'minimum_length'
  >;
  valid: boolean;
  validation: () => boolean;
  text: string;
}

@Component({
  selector: 'app-password-rule-box',
  templateUrl: './password-rule-box.component.html',
  styleUrls: ['./password-rule-box.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule],
})
export class PasswordRuleBoxComponent implements OnChanges {
  @Input() passwordPolicy: PasswordPolicy;
  @Input() password: string;

  @Input() validPassword = false;
  @Output() validPasswordChange = new EventEmitter<boolean>();

  validateRules: validateRule[] = [
    {
      key: 'is_needed_lower_case',
      valid: false,
      validation: (): boolean => {
        return this.match(/[a-z]/g);
      },
      text: 'VALIDATION.LOWER-CHARACTER',
    },
    {
      key: 'is_needed_upper_case',
      valid: false,
      validation: (): boolean => {
        return this.match(/[A-Z]/g);
      },
      text: 'VALIDATION.UPPER-CHARACTER',
    },
    {
      key: 'is_needed_digit',
      valid: false,
      validation: (): boolean => {
        return this.match(/[0-9]/g);
      },
      text: 'VALIDATION.DIGIT-CHARACTER',
    },
    {
      key: 'is_needed_special_case',
      valid: false,
      validation: (): boolean => {
        return this.match(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g);
      },
      text: 'VALIDATION.SPECIAL-CHARACTER',
    },
    {
      key: 'minimum_length',
      valid: false,
      validation: (): boolean => {
        return (
          this.password.length >= this.passwordPolicy.minimum_length
        );
      },
      text: '',
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['password']) {
      this.validationPassword();
    }
  }

  match(pattern: RegExp): boolean {
    return !!this.password.match(pattern);
  }

  validationPassword(): void {
    if (!this.password) {
      this.validateRules.forEach((obj) => (obj.valid = false));
      this.validPassword = false;
      this.validPasswordChange.emit(this.validPassword);
      return;
    }
    this.validateRules.forEach((obj: validateRule) => {
      const key = obj.key;
      if (this.passwordPolicy[key]) {
        obj.valid = obj.validation();
      } else {
        obj.valid = true;
      }
    });
    this.validPassword = !this.validateRules.filter(
      (obj: validateRule) => !obj.valid,
    ).length;
    this.validPasswordChange.emit(this.validPassword);
  }

  getIconPath(condition: boolean): string {
    return condition
      ? 'marks/circle-check.svg#circle-check'
      : 'marks/exclaimation-circle.svg#exclaimation-circle';
  }

  get passwordStatus(): string {
    const checkCondition = this.validateRules.map((obj) => obj.valid);
    if (!this.password) {
      return 'VALIDATION.Please enter your password';
    } else if (checkCondition.some((condition) => !condition)) {
      return 'VALIDATION.Weak Password';
    } else {
      return 'VALIDATION.Strong Password';
    }
  }
}
