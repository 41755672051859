import { Injectable } from '@angular/core';
import { LoaListParams, LoaParams } from '@shared/models/loa.model';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from 'src/app/shared/service/api.constant';
import { LoaListItem, Member, NonUser } from './loa.model';

@Injectable({
  providedIn: 'root',
})
export class LoaService {
  constructor(private http: ApiService) {}

  getLOAList(params?: LoaListParams) {
    return this.http.get<LoaListItem[]>(API_URL.loa_groups, params);
  }

  createNewLOA(data: any) {
    return this.http.post(API_URL.loa_groups, data);
  }

  updateLOA(id: number, data: any) {
    return this.http.put(API_URL.loa_groups + id + '/', data);
  }

  removeLOA(id: number) {
    return this.http.delete(API_URL.loa_groups + id + '/');
  }

  getLOAData(id: any, params?: LoaParams) {
    return this.http.get(API_URL.loa_groups + id + '/', params);
  }

  batchUploadFile(data: any, type: any) {
    return this.http.post(`/api/batch-uploads/${type}/`, data);
  }

  validateApprover(signer: Partial<Member>): boolean | any {
    let isValid = false;
    if (signer.person == null && signer.no_person) {
      isValid = true;
    } else if (signer.person == null) {
      isValid = false;
    } else if (typeof signer.person === 'object') {
      isValid = [
        'email',
        'name',
        'job_position',
        'phone_number',
      ].every((validateKey) => {
        return !!(signer.person as NonUser | any)?.[validateKey];
      });
    } else if (typeof signer.person === 'number') {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  }
}
