import {
  Directive,
  Output,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Directive({
  selector: '[appScrollTracker]',
})
export class ScrollTrackerDirective implements OnDestroy {
  @Input() nearEndThreshold = 250;
  @Output() nearEnd = new EventEmitter<void>();
  @Output() nearEndCold = new EventEmitter<void>();
  @Output() scrollingFinished = new EventEmitter<void>();

  nearEndScroll$ = new Subject<void>();

  private subscription = new Subscription();

  constructor() {
    this.subscribeOnScroll();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    if (!event.target) {
      throw new Error('Not found target element');
    }
    this.checkScrollEnd(event.target as HTMLElement);
    this.checkScrollNearEnd(event.target as HTMLElement);
  }

  checkScrollEnd(element: HTMLElement) {
    if (
      element.offsetHeight + element.scrollTop >=
      element.scrollHeight - 5
    ) {
      this.scrollingFinished.emit();
    }
  }

  checkScrollNearEnd(element: HTMLElement) {
    const isNearEnd = this.isNearEndScroll(element);
    if (isNearEnd) {
      this.nearEnd.emit();
    }
  }

  isNearEndScroll(element: HTMLElement): boolean {
    const contentEl = element;
    const scrollHeight = contentEl.scrollHeight || 0;
    if (scrollHeight === 0) {
      return false;
    }
    const clientHeight = contentEl.clientHeight;
    const offsetTop = contentEl.scrollTop || 0;
    return (
      scrollHeight - (offsetTop + clientHeight) <=
      this.nearEndThreshold
    );
  }

  subscribeOnScroll(): void {
    const listener = this.nearEnd.pipe(debounceTime(70)).subscribe({
      next: () => {
        this.nearEndCold.emit();
      },
    });
    this.subscription.add(listener);
  }
}
