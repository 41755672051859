<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'form'"
      [ngTemplateOutlet]="formTemp"></ng-container>
  <ng-container *ngSwitchCase="'success'"
      [ngTemplateOutlet]="successTemp"></ng-container>
</ng-container>

<ng-template #formTemp>
  <div class="text-center text-black-neutrals fs-u4 fw-bold">{{ "CREATE-USER.Reset password"|translate }}</div>
  <div class="mx-2">
    <app-new-password-form
        [isLoading]="isLoading"
        [errors]="errors"
        (next)="send($event)"
    ></app-new-password-form>
  </div>
</ng-template>

<ng-template #successTemp>
  <div class="d-flex align-items-center flex-column justify-content-center">
    <app-svg-page-placeholder
        class="pt-2"
        [style.width.px]="315"
        [height]="152"
        [width]="315"
        [standImage]="{src: 'assets/images/pictograms/key-lock.svg#key-lock', width: 132, height: 132, x: 100, y: 10}"
        [backgroundImage]="{fill: '#E8EEFB', width: 315, height: 108, y: 23}">
    </app-svg-page-placeholder>
    <div class="text-center text-black-neutrals fs-u4 fw-bold">{{ "RESET-PASS.SUCCESS-TITLE"|translate }}</div>
    <div class="text-center text-gray-neutrals fs-base-l5 fs-base-lg-l2 pt-lg-u0">
      <div>{{ 'RESET-PASS.SUCCESS-SUBTITLE-1' | translate }}</div>
      <div>{{ 'RESET-PASS.SUCCESS-SUBTITLE-2' | translate }}</div>
    </div>
    <div class="w-100 mt-base-u8 pt-base-lg-l8 d-flex justify-content-center">
      <button class="w-100 btn btn-std btn-h-res fs-base-l0 btn-std-prim"
          [style.max-width.px]="320"
          (click)="login()">
        {{ 'LOGIN.LOGIN' | translate }}
      </button>
    </div>
  </div>
</ng-template>