import { Component, OnDestroy } from '@angular/core';
import {
  ThemeList,
  ThemeService,
} from '@shared/service/theme.service';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-locked',
  templateUrl: './account-locked.component.html',
  styleUrls: ['./account-locked.component.scss'],
})
export class AccountLockedComponent implements OnDestroy {
  theme: ThemeList;
  private readonly ngDestroy: Subject<void> = new Subject();

  constructor(
    private themeService: ThemeService,
    private router: Router,
  ) {
    this.themeService.data
      .pipe(takeUntil(this.ngDestroy))
      .subscribe((theme) => (this.theme = theme));
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  goToForgetPassword() {
    this.router.navigate(['/forget-password']);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
