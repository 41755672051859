import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TranslateModule } from '@ngx-translate/core';
import { SignatureUploadComponent } from './signature-upload/signature-upload.component';
import { SignatureCropperComponent } from './signature-cropper/signature-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SignatureSignNowComponent } from './signature-sign-now/signature-sign-now.component';
import { CanvasWhiteboardModule } from '@preeco-privacy/ng2-canvas-whiteboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { LaddaModule } from 'angular2-ladda';
import { ColorChromeModule } from 'ngx-color/chrome';

@NgModule({
  declarations: [
    SignatureUploadComponent,
    SignatureCropperComponent,
    SignatureSignNowComponent,
  ],
  imports: [
    CommonModule,
    NgxFileDropModule,
    TranslateModule,
    ImageCropperModule,
    AngularSvgIconModule,
    NgbTooltip,
    CanvasWhiteboardModule,
    ReactiveFormsModule,
    NgClickOutsideDirective,
    LaddaModule,
    FormsModule,
    ColorChromeModule,
  ],
  exports: [
    SignatureUploadComponent,
    SignatureCropperComponent,
    SignatureSignNowComponent,
  ],
})
export class SignatureModule {}
