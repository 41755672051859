<div class="d-flex flex-column align-items-center justify-content-center text-center">
  <svg class="col-auto"
      [ngClass]="customSvgClass"
      [attr.height]="height"
      [attr.width]="width"
      [attr.viewBox]="viewBox">
    <use *ngIf="backgroundImageAttributes['src']"
        [attr.height]="backgroundImageAttributes['height']"
        [attr.href]="backgroundImageAttributes['src']"
        [attr.fill]="backgroundImageAttributes['fill']"
        [attr.width]="backgroundImageAttributes['width']"
        [attr.x]="backgroundImageAttributes['x']"
        [attr.y]="backgroundImageAttributes['y']">
    </use>
    <use *ngIf="standImageAttributes['src']"
        class="pictogram-front"
        [attr.href]="standImageAttributes['src']"
        [attr.fill]="standImageAttributes['fill']"
        [attr.height]="standImageAttributes['height']"
        [attr.width]="standImageAttributes['width']"
        [attr.stroke]="standImageAttributes['stroke']"
        [attr.x]="standImageAttributes['x']"
        [attr.y]="standImageAttributes['y']">
    </use>
  </svg>
  <label class="text-black-pantone fw-bold lh-normal mt-l4 mb-l12">
    {{ (title || '') | translate }}
  </label>
  <label class="text-gray-neutrals fs-l2 lh-normal mb-0">
    {{ (subTitle || '') | translate }}
  </label>
</div>