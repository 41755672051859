import { Injectable } from '@angular/core';
import {
  Action,
  createModelSelector,
  createPropertySelectors,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  AnnouncementToggleMenu,
  ClearAnnouncementState,
  ClearSelectAnnouncement,
  EmptyContentAnnouncement,
  ShowAnnouncementDetail,
} from './new-feed-announcement.actions';
import { NewFeedAnnouncementStateModel } from './new-feed-announcement.models';
import { NewsFeedService } from '../../modules/new-feed/shared/news-feed.service';
import { SpinnerService } from '@core/services/spinner.service';
import { finalize } from 'rxjs';
import { ErrorNotification } from '../memo/memo.actions';
import { SidebarService } from '@core/components/sidebar/sidebar.service';

@State<NewFeedAnnouncementStateModel>({
  name: 'newFeedAnnouncement',
  defaults: {
    announcement: null,
    showingMenu: true,
    emptyContent: true,
    viaLink: false,
  },
})
@Injectable({
  providedIn: 'root',
})
export class NewFeedAnnouncementState {
  constructor(
    private service: NewsFeedService,
    private spinnerService: SpinnerService,
    private store: Store,
    private sidebarService: SidebarService,
  ) {}

  @Action(ShowAnnouncementDetail)
  showAnnouncementDetail(
    {
      setState,
      getState,
    }: StateContext<NewFeedAnnouncementStateModel>,
    payload: { id: number; viaLink?: boolean },
  ) {
    this.spinnerService.show();
    this.service
      .getAnnouncementDetail(payload.id)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        }),
      )
      .subscribe(
        (res) => {
          const state = getState();
          const viaLink = payload.viaLink || state.viaLink;
          setState({
            ...state,
            announcement: res,
            viaLink: viaLink,
          });
          if (!viaLink) {
            this.sidebarService.fetchBadge();
          }
        },
        (error: any) => {
          this.store.dispatch(new ErrorNotification(error));
        },
      );
  }

  @Action(ClearSelectAnnouncement)
  clearSelectAnnouncement({
    patchState,
  }: StateContext<NewFeedAnnouncementStateModel>) {
    patchState({
      announcement: null,
    });
  }

  @Action(AnnouncementToggleMenu)
  toggleMenu({
    patchState,
    getState,
  }: StateContext<NewFeedAnnouncementStateModel>) {
    const state = getState();
    patchState({
      showingMenu: !state.showingMenu,
    });
  }

  @Action(ClearAnnouncementState)
  ClearAnnouncementState({
    setState,
  }: StateContext<NewFeedAnnouncementStateModel>) {
    setState({
      announcement: null,
      showingMenu: true,
      emptyContent: true,
      viaLink: false,
    });
  }

  @Action(EmptyContentAnnouncement)
  emptyContent(
    { patchState }: StateContext<NewFeedAnnouncementStateModel>,
    { emptyContent }: { emptyContent?: boolean },
  ) {
    patchState({
      emptyContent: emptyContent,
    });
  }
}

export class AnnouncementSelectors {
  static getSlices =
    createPropertySelectors<NewFeedAnnouncementStateModel>(
      NewFeedAnnouncementState,
    );
  static getBasicSlices = createModelSelector({
    showingMenu: AnnouncementSelectors.getSlices.showingMenu,
    emptyContent: AnnouncementSelectors.getSlices.emptyContent,
  });
}
