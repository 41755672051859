import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as pdfjsLib from 'pdfjs-dist';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * In case `pdf.worker.js` version < `2.9.359`.
 * We need to set custom pdf worker path for enabling digital signature in pdf.
 * More info: https://stackoverflow.com/questions/57380989/preview-pdf-file-with-e-signature-in-angular
 * We can download PDF worker js file from https://cdn.jsdelivr.net/npm/pdfjs-dist@{PDF_WORKER_VERSION_HERE}/legacy/build/pdf.worker.min.js
 *
 * NOTE: `pdfjs-dist` module required a same minor version for `pdf.worker.js`.
 *
 * This below is setting up custom pdf.worker.js path.
 */
const workerSrc = '/assets/scripts/pdf.worker-3.11.174.min.js';
(<any>window).pdfWorkerSrc = workerSrc;
pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
