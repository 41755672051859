<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'form'"
      [ngTemplateOutlet]="formTemp"></ng-container>
  <ng-container *ngSwitchCase="'success'"
      [ngTemplateOutlet]="successTemp"></ng-container>
</ng-container>

<ng-template #formTemp>
  <div class="mb-base-u8 text-center text-lg-start">
    <h4 class="fs-base-l1 fs-base-lg-u11 fw-bold mb-base-l8 mb-lg-u8">
      {{ 'LOGIN.FORGOT-PASSWORD' | translate }}
    </h4>
    <div class="text-gray-neutrals fs-base-l5 fs-base-lg-l2">
      {{ 'LOGIN.PASS-WORK-RESET-TEXT' | translate }}
    </div>
  </div>
  <form #fg="ngForm"
      [formGroup]="form"
      (ngSubmit)="send()"
      class="row login-form">
    <div class="col-12">
      <label class="d-flex align-items-center">
        <span class="ms-l8">{{ 'LOGIN.EMAIL' | translate }}</span>
        <span class="text-asterisk">*</span>
      </label>
      <input id="email-input"
          type="text"
          autocomplete="off"
          class="form-control"
          formControlName="email"
          [ngClass]="{'is-invalid': (fg.submitted && form.controls['email'].invalid)}"
          [placeholder]="'LOGIN.EMAIL' | translate">
      <div *ngIf="f.email.errors?.be_error"
          class="invalid-feedback">
        {{ f.email.errors.be_error[currentLang] }}
      </div>
    </div>
    <div id="g-recaptcha"
        class="col-12 mt-base-u8">
      <ngx-recaptcha2 [siteKey]="siteKey"
          (reset)="handleReset()"
          (expire)="handleExpire()"
          (error)="handleError()"
          (load)="handleLoad()"
          (success)="handleSuccess($event)"
          [useGlobalDomain]="false"
          hl="en"
          formControlName="recaptcha">
      </ngx-recaptcha2>
    </div>
    <div class="col-12 mt-base-u8 pt-base-md-l8">
      <button class="w-100 btn btn-std btn-h-res fs-base-l0 btn-std-prim"
          [ladda]="loading"
          type="submit">
        {{ "LOGIN.RESET-PASSWORD" | translate }}
      </button>
    </div>
    <div class="col-12 text-center mt-base-u8 pt-base-md-l8">
      <span class="text-gray-neutrals fs-base-l2 pointer"
          (click)="login.emit()">
        {{ 'LOGIN.BACK-LOGIN' | translate }}
      </span>
    </div>
  </form>
</ng-template>

<ng-template #successTemp>
  <div class="d-flex align-items-center flex-column justify-content-center">
    <app-svg-page-placeholder
        class="pt-2"
        [style.width.px]="315"
        [height]="152"
        [width]="315"
        [standImage]="{src: 'assets/images/pictograms/open-mail.svg#open-mail', width: 142, height: 152, x: 90, y: 0}"
        [backgroundImage]="{fill: '#E8EEFB', width: 315, height: 104, y: 23}">
    </app-svg-page-placeholder>
    <div class="text-center text-gray-neutrals fs-base-l5 fs-base-lg-l2 pt-lg-u0">
      <div>{{ 'LOGIN.EMAIL-RESET-TEXT-1' | translate }}</div>
      <div>{{ 'LOGIN.EMAIL-RESET-TEXT-2' | translate }}</div>
    </div>
    <div class="w-100 mt-base-u8 pt-base-lg-l8 d-flex justify-content-center">
      <button class="w-100 btn btn-std btn-h-res fs-base-l0 btn-std-prim"
          [style.max-width.px]="320"
          (click)="login.emit()">
        {{ 'LOGIN.BACK-LOGIN' | translate }}
      </button>
    </div>
  </div>
</ng-template>