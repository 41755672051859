import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NDID } from './ndid.model';
import {
  SetCitizenId,
  SetIAL,
  SetSelectedIdpNodeId,
} from './ndid.actions';

@State<NDID>({
  name: 'ndid',
  defaults: {
    citizen_id: '',
    ial: 1.1,
    selectedIdpNode: {
      node_id: '',
      marketing_name_th: '',
      marketing_name_en: '',
      on_the_fly: false,
    },
  },
})
@Injectable({
  providedIn: 'root',
})
export class NdidState {
  @Action(SetCitizenId)
  setCitizenId(
    { setState, getState }: StateContext<NDID>,
    { citizenId }: { citizenId: string },
  ): void {
    const state = getState();

    setState({
      ...state,
      citizen_id: citizenId,
    });
  }

  @Action(SetIAL)
  setIAL(
    { setState, getState }: StateContext<NDID>,
    { ial }: { ial: number },
  ): void {
    const state = getState();

    setState({
      ...state,
      ial: ial,
    });
  }

  @Action(SetSelectedIdpNodeId)
  setSelectedIdpNodeId(
    { setState, getState }: StateContext<NDID>,
    { selectedIdpNode }: any,
  ): void {
    const state = getState();
    state.selectedIdpNode = selectedIdpNode;

    setState({
      ...state,
    });
  }
}
