<div *ngIf="role"
    class="text-center text-lg-start mx-1">
  <h4 class="fs-base-l1 fs-base-lg-u11 fw-bold mb-0">
    {{ (isResettingPassword ? 'RESET-PASS.SET-PASSWORD' : 'RESET-PASS.Your password has expired.') | translate }}
  </h4>
  <div class="text-gray-neutrals fs-base-l5 fs-base-lg-l2">
    {{ 'RESET-PASS.EXPIRE-SUB' | translate }}
  </div>
</div>
<div class="mx-1">
  <app-new-password-form
      [isLoading]="isLoading"
      [errors]="errors"
      (next)="send($event)"
  ></app-new-password-form>
</div>