import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/authentication.service';
import { AlertService } from '@core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorNotification } from '../../../store/memo/memo.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  @Output() login = new EventEmitter();

  form: UntypedFormGroup;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public siteKey = '6LeoZ70dAAAAAP2ZkEanBnuBvtPsL0I97HVKHKGn';

  subscription: Subscription[] = [];
  errors: { [key: string]: string } = {};

  mode: 'form' | 'success' = 'form';
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private alert: AlertService,
    private translate: TranslateService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        [Validators.required, this.backendErrorValidation('email')],
      ],
      recaptcha: ['', Validators.required],
    });
  }

  backendErrorValidation(key: string): ValidatorFn {
    return (
      control: AbstractControl,
    ): { [key: string]: string } | null => {
      return this.errors[key] ? { be_error: this.errors[key] } : null;
    };
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  handleError(): void {}

  validate() {
    this.f.email.updateValueAndValidity();
  }

  send() {
    this.errors = {};
    this.validate();
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    const subscription = this.authenticationService
      .forgotPassword({
        email: this.f.email.value,
      })
      .subscribe(
        () => {
          this.mode = 'success';
          this.loading = false;
        },
        (error) => {
          if (error.status === 400) {
            if (error?.error === 'IS_AD_USER') {
              this.alert.error(
                'this is adfs user. please contact adfs admin.',
              );
            } else {
              this.errors = error.error;
              this.validate();
            }
          } else {
            this.store.dispatch(new ErrorNotification(error));
          }
          this.loading = false;
        },
      );
    this.subscription.push(subscription);
  }

  get f(): { [key: string]: AbstractControl } | any {
    return this.form.controls;
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
