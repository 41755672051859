import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { ModalConfirmationModule } from '@shared/components/modal-confirmation/modal-confirmation.module';

@Component({
  selector: 'app-modal-wrong-password-attempt',
  templateUrl: './modal-wrong-password-attempt.component.html',
  styleUrls: ['./modal-wrong-password-attempt.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, ModalConfirmationModule],
})
export class ModalWrongPasswordAttemptComponent {
  @Input() remainingAttempts: number | null;
  @Input() username: string;

  @Output() closePopup = new EventEmitter<void>();
  @Output() forgetPassword = new EventEmitter<void>();

  private translate = inject(TranslateService);

  _close() {
    this.closePopup.emit();
  }

  _forgetPassword() {
    this.forgetPassword.emit();
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
