import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {
  constructor() {}

  base64ToFile(data: string, filename = 'photo.png') {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  base64ToBinary(base64String: string): Uint8Array {
    const byteString = atob(base64String.split(',')[1]);
    const byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return byteArray;
  }

  base64ToBlobUrl(
    base64String: string,
    contentType?: string,
  ): string {
    const base64 = base64String.split(',')[1]; // Remove data URL prefix if present
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });
    return URL.createObjectURL(blob);
  }

  blobToFile(blob: Blob, filename = 'photo.png') {
    return new File([blob], filename, {
      type: blob?.type,
    });
  }

  downloadFile(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    this.downloadUrlFile(url, filename);
    URL.revokeObjectURL(url);
  }

  downloadUrlFile(
    url: string,
    filename: string,
    anchorTarget = '_self',
  ) {
    const element = document.createElement('a');
    document.body.appendChild(element);
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    element.target = anchorTarget;
    element.click();
    document.body.removeChild(element);
  }

  fileListToArr(fileList: FileList): Array<File> {
    const files: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file != null) files.push(file);
    }
    return files;
  }

  getFileToUrl(
    file: Blob | File,
  ): Observable<string | ArrayBuffer | null> {
    const reader = new FileReader();
    const obs = new Observable<FileReader['result']>((subscriber) => {
      reader.onload = (event) => {
        subscriber.next(event.target?.result);
        subscriber.complete();
      };
    });
    reader.readAsDataURL(file);
    return obs;
  }

  getFileToArrayBuffer(
    file: Blob | File,
  ): Observable<ArrayBuffer | null> {
    const reader = new FileReader();
    const obs = new Observable<Exclude<FileReader['result'], string>>(
      (subscriber) => {
        reader.onloadend = (event) => {
          subscriber.next(
            event.target?.result as ArrayBuffer | undefined | null,
          );
          subscriber.complete();
        };
      },
    );
    reader.readAsArrayBuffer(file);
    return obs;
  }

  getHumanFileSize(bytes: number, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + ' ' + units[u];
  }
}
