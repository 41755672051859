import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Tab } from './slide-tab-bar.component';

@Injectable()
export class SliderTabBarService implements OnDestroy {
  tabs: Tab[] = [];

  private badgeChange$ = new Subject<BadgeChangeEvent>();

  constructor() {}

  ngOnDestroy(): void {
    this.badgeChange$.complete();
  }

  getTabByValue(key: string, tabs?: Tab[]): Tab | undefined {
    return (tabs || this.tabs).find((tab) => tab.value === key);
  }

  patchBadge(patchValues: { [key: string]: number }) {
    Object.keys(patchValues).forEach((key) => {
      const index = this.tabs.findIndex((tab) => tab.value === key);
      if (index !== -1) {
        this.tabs[index].badge = patchValues[key];
      }
    });
    this.badgeChange$.next({
      event: `patchBadge`,
      patchValues: patchValues,
    });
  }

  setBadge(index: number, badge: number): void {
    this.tabs[index].badge = badge;
    this.badgeChange$.next({
      event: `setBadge at index: ${index}`,
      patchValues: {
        [index]: badge,
      },
    });
  }

  setBadges(badges: number[]) {
    badges.forEach((badge, index) => {
      this.tabs[index].badge = badge;
    });
    this.badgeChange$.next({
      event: `setBadges`,
      patchValues: badges,
    });
  }

  get badgeChange() {
    return this.badgeChange$.asObservable();
  }
}

export interface BadgeChangeEvent {
  event: string;
  patchValues: any;
}
