<div class="content-box d-flex flex-column">
  <div class="order-1 order-md-1">
    <div class="title mb-l12">Dashboard</div>
    <div>"{{ lang === 'th' ? quote_th[quote_rd_in] : quote_en[quote_rd_in] }}"</div>
  </div>
  <app-saved-dashboard-slider class="order-3 order-md-2"
      [tabs]="tabs"></app-saved-dashboard-slider>
  <div class="order-2 order-md-3 lm-tree text-black-deep d-flex align-items-center p-l2">
    <svg width="60"
        height="60"
        viewBox="0 0 60 60">
      <use height="60"
          width="60"
          href="assets/images/icons/saved-db/big-tree.svg#big-tree"></use>
    </svg>
    <div class="ms-l2">
      <p class="fw-bold mb-l12">{{ "LOGIN.DASHBOARD.Congratulations" | translate }}</p>
      <p class="fs-l2 fw-medium mb-l6">{{ "LOGIN.DASHBOARD.SAVE-LAST-MONTH" | translate }}</p>
      <div *ngIf="lmTree"
          class="badge rounded-pill">
        <span class="fs-u0">{{ lmTree.value }} {{ lmTree.unit[lang] }}</span>
      </div>
    </div>
  </div>
  <div #videoContainer
      *ngIf="deviceType !== 'mobile'"
      class="d-none d-md-inline order-md-4 video-container"
      [ngClass]="{'video-footer': deviceType !== 'desktop'}">
    <video *ngIf="show && videoPath"
        autoplay
        loop
        [muted]="'muted'"
        [style.transform]="scale">
      <source [src]="videoPath"
          type="video/mp4">
    </video>
  </div>
</div>
