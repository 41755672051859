import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LaddaModule } from 'angular2-ladda';
import {
  CustomModalBodyDirective,
  CustomModalFooterDirective,
  CustomModalHeaderDirective,
  ModalConfirmationComponent,
} from './modal-confirmation.component';

@NgModule({
  declarations: [
    CustomModalHeaderDirective,
    CustomModalBodyDirective,
    CustomModalFooterDirective,
    ModalConfirmationComponent,
  ],
  exports: [
    CustomModalHeaderDirective,
    CustomModalBodyDirective,
    CustomModalFooterDirective,
    ModalConfirmationComponent,
  ],
  imports: [CommonModule, LaddaModule, TranslateModule],
})
export class ModalConfirmationModule {}
