import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { map, Observable } from 'rxjs';
import { NavbarService } from '../components/navbar/navbar.service';
import { GetUserInfo } from '../../store/auth/auth.actions';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navbar: NavbarService,
    private store: Store,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const currentUser = this.authenticationService.currentUserValue;
    const userRole = this.authenticationService.role;
    const permittee = this.getPermittee(route.data['permission']);
    if (currentUser) {
      if (userRole) {
        return this.verifyRole(permittee);
      } else {
        return this.getRole().pipe(
          map(() => {
            return this.verifyRole(permittee);
          }),
        );
      }
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  verifyRole(permittee: string[]): boolean {
    const role = this.authenticationService.role;
    if (!this.authenticationService.isRegister) {
      this.router.navigateByUrl('/register');
      return false;
    }
    if (!this.authenticationService.isPasswordValid) {
      this.router.navigateByUrl('/reset-password');
      return false;
    }
    if (permittee.includes(role) || !permittee.length) {
      this.navbar.setActiveSidebar(true);
      return true;
    } else {
      this.router.navigate(['/page-not-found'], {
        queryParams: { permission: true },
      });
      return false;
    }
  }

  // tslint:disable-next-line:max-line-length
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      this.navbar.setActiveSidebar(true);
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }

  getRole(): Observable<string> {
    return this.store.dispatch(GetUserInfo).pipe(
      map(() => {
        return this.authenticationService.role;
      }),
      tap({
        error: (error) => {
          this.router.navigateByUrl('/login');
        },
      }),
    );
  }

  getPermittee(permission: any): string[] {
    let permittee: string[] = [];
    if (permission != null) {
      if (typeof permission === 'boolean' && !permission) {
        permittee = ['nobody'];
      } else if (typeof permission === 'string') {
        permittee = [permission];
      } else if (typeof permission === 'number') {
        permittee = [permission.toString()];
      } else if (Array.isArray(permission)) {
        permittee = [...permission];
      } else if (typeof permission === 'function') {
        permittee = permission();
      } else {
        permittee = Object.keys(permission).filter(
          (key) => permission[key],
        );
      }
    }
    return permittee;
  }
}
