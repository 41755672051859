<app-modal-confirmation-std4-skin [header]="'COMMON.Logout Confirmation' | translate"
    [title]="'COMMON.Are you sure you want to log out' | translate"
    [submitText]="'COMMON.Confirm' | translate"
    [dismissText]="'COMMON.Cancel' | translate"
    (submitted)="logOut()"
    (keydown.enter)="logOut()"
    (dismiss)="dismissModal()">
  <ng-template>
    <div class="icon-border">
      <svg height="24"
          width="24"
          fill="currentColor">
        <use height="24"
            width="24"
            href="assets/images/pictograms/opened-door.svg#opened-door">
        </use>
      </svg>
    </div>
  </ng-template>
</app-modal-confirmation-std4-skin>