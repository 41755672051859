import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../navbar/navbar.service';
import { NO_PERMISSION_WORDING } from '@shared/utils/memos.constant';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  hasPermission = false;
  memo_status: string;
  noPermissionDetail: any = null;

  constructor(
    private activateRoute: ActivatedRoute,
    private navbar: NavbarService,
  ) {
    this.navbar.setActiveSidebar(false);
    this.activateRoute.queryParams.subscribe((res: any) => {
      this.hasPermission = !!res.permission;
      this.memo_status = res.memo_status;
      if (this.memo_status) {
        this.noPermissionDetail =
          NO_PERMISSION_WORDING[this.memo_status];
      } else {
        this.noPermissionDetail = {
          iconType: 'img',
          iconPath: this.hasPermission
            ? 'assets/images/permission_denied.svg'
            : 'assets/images/404.svg',
          title: this.hasPermission
            ? 'Permission Denied'
            : 'Page not found...',
          subtitle: this.hasPermission
            ? `Sorry, but you don't have permission to access this page.`
            : `Sorry, we're unable to find the page you're looking for.`,
        };
      }
    });
  }
}
