import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  toastNoti = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
  });

  constructor() {}

  toastNotification(objectKey: any) {
    if ('errorDetail' in objectKey) {
      this.toastNoti.fire({
        icon: objectKey.icon,
        html: `${objectKey.content} <br /> <span class="text-asterisk"> ${objectKey.errorDetail} </span>`,
        timer: objectKey.timer || 4000,
      });
    } else {
      const defaultTitle =
        objectKey.type === 'error'
          ? 'System error, Please contact technical support.'
          : 'SAVE CHANGE';
      this.toastNoti
        .fire({
          icon: objectKey.type,
          // type: objectKey['type'],
          title: objectKey.content || defaultTitle,
          timer: objectKey.timer || 4000,
        })
        .then((result) => {
          return result;
        });
    }
  }
}
// type: objectKey['type'],
// html: `${objectKey['content']} <br /> <span class="text-asterisk"> ${objectKey['errorDetail']} </span>`,
// timer: objectKey['timer'] || 4000,

//       type: objectKey['type'],
// title: objectKey['content'] || default_title,
// timer: objectKey['timer'] || 4000,
