const url = window.location.href;
// eslint-disable-next-line no-useless-escape
const matches: any = url.match(/^http(s?)?\:\/\/([^\/?#]+)/);
const baseUrl = matches[0];
const websocketUrl = baseUrl.replace('http', 'ws') + '/ws/';

export const environment = {
  production: false,
  baseUrl,
  websocketUrl,
};

export const featureFlag: any = {
  ddoc: false,
  ddoc_enable_feature_26: false,
  ddoc_enable_feature_28: false,
  timestamp_memo_enable: false,
  ndid: false,
  login_adfs_enable: false,
  thaid_login_enable: false,
  maintenance_mode: false,
  maintenance_message_th: '',
  maintenance_message_en: '',
  sms_otp: false,
  email_otp: false,
  thaicom: false,
  self_service_form: false,
  auto_widget_enable: false,
  google_client_id: '',
  export_memo_google_drive_enable: false,
  media_url_need_authentication: true,
  share_folder_enable: false,
  multi_approval_enable: false,
  company_name: '',
  carbon_accounting_enable: false,
  download_detail_enable: false,
  loh_enable: false,
  google_chat_notification_enable: false,
  microsoft_teams_notification_enable: false,
};
