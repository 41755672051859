import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
  ],
})
export class LangSelectorComponent {
  private translate = inject(TranslateService);

  @Input() style: 'text' | 'dropdown' = 'text';
  @Input() enableHover = true;

  changeLanguage(lang: string): void {
    if (lang === 'en') {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
    }
    if (lang === 'th') {
      this.translate.use('th');
      localStorage.setItem('lang', 'th');
    }
  }
  get currentLang(): string {
    return this.translate.currentLang;
  }
}
