<app-modal-confirmation
    [title]="'PROFILE.Warning'">
  <ng-template appCustomModalBody>
    <div class="modal-body d-flex justify-content-center flex-column align-items-center wrong-password">
      <svg height="56"
          width="56"
          fill="#f53636">
        <use height="56"
            width="56"
            href="assets/images/icons/alert/exclamation-circle-fill.svg#exclamation-circle-fill"></use>
      </svg>
      <ng-container *ngIf="remainingAttempts !== -999">
        <p class="fw-bold mt-4 title">{{ "AUTH.Your account will be locked" | translate }}</p>
        <p>{{ "AUTH.Login with" | translate }} <span class="fw-medium text-black-pantone">{{ username }}</span></p>
        <p>{{ "AUTH.will be locked due to multiple failed login attempts" | translate }}</p>
        <p class="text-red-pig-blood fw-bold">{{ "AUTH.You’ll have" | translate }}
          {{ remainingAttempts }}
          {{ "AUTH.more attempt before your account is locked for 10 minutes"|translate }}
        </p>
        <p>{{ 'PROFILE.Please click' | translate }}
          <span class="text-black-pantone fw-medium">"{{ 'PROFILE.Forget Password' | translate | lowercase }}"</span>
          {{ 'PROFILE.to reset your password' | translate }}
        </p>
        <button type="button"
            class="btn btn-std btn-h-res fs-base-l0 btn-std-prim mt-4"
            href="javascript:void(0)"
            (click)="_forgetPassword()">
          {{ 'PROFILE.Forget Password' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="remainingAttempts === -999">
        <p class="fw-bold mt-4 title mb-0">
          {{ "AUTH.You are unable to login because your account"|translate }}
        </p>
        <p class="fw-bold title">
          {{ "AUTH.has been temporarily disabled."|translate }}
        </p>
        <p>{{ "AUTH.If you want to use this account, please cantact admin."|translate }}</p>
        <button type="button"
            class="btn btn-std btn-h-res fs-base-l0 btn-std-prim mt-4"
            href="javascript:void(0)"
            (click)="_close()">
          {{ "UPLOAD.OK"|translate }}
        </button>
      </ng-container>
    </div>
  </ng-template>
  <ng-template appCustomModalFooter></ng-template>
</app-modal-confirmation>
