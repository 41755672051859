<div class="container-tabs">
  <div class="d-none d-md-flex container-btn-scroll">
    <div class="btn btn-std-outline btn-scroll-left d-flex align-items-center justify-content-center text-black-pantone"
        (click)="onPreviousClick()"
        (mousedown)="onNavigateHold('left')"
        (mouseup)="onNavigateRelease()">
      <svg height="24"
          stroke="currentColor"
          width="24">
        <use height="24"
            width="24"
            href="assets/images/icons/arrows/chevron-left.svg#chevron-left">
        </use>
      </svg>
    </div>
    <div class="btn btn-std-outline btn-scroll-right d-flex align-items-center justify-content-center text-black-pantone"
        (click)="onNextClick()"
        (mousedown)="onNavigateHold('right')"
        (mouseup)="onNavigateRelease()">
      <svg height="24"
          stroke="currentColor"
          width="24">
        <use height="24"
            width="24"
            href="assets/images/icons/arrows/chevron-right.svg#chevron-right">
        </use>
      </svg>
    </div>
  </div>
  <div #tabWrapper
      class="d-none d-md-flex tab-wrapper scrollbar-invisible mx-0">
    <div class="tab-row row gx-1 flex-nowrap align-items-center position-relative">
      <div *ngFor="let tab of tabs; index as i"
          class="col-max tab-item text-black-deep"
          [style.background-color]="tab.color?.background">
        <ng-container [ngTemplateOutlet]="itemTemp"
            [ngTemplateOutletContext]="{tab}"></ng-container>
      </div>
    </div>
  </div>
  <div class="d-md-none slide-mb row">
     <div *ngFor="let tab of tabs; index as i"
          class="col-mb tab-item text-black-deep"
          [style.background-color]="tab.color?.background">
        <ng-container [ngTemplateOutlet]="itemTemp"
            [ngTemplateOutletContext]="{tab}"></ng-container>
      </div>
  </div>
</div>

<ng-template #itemTemp
  let-tab="tab">
  <div class="item d-flex justify-content-between">
    <svg class="icon-status"
        [style.background-color]="tab.color?.foreground"
        stroke="currentColor"
        viewBox="0 0 20 20">
      <use height="20"
          width="20"
          [attr.href]="tab.iconPath"></use>
    </svg>
    <p class="fs-u4 fw-bold"> {{ tab.title | translate }}</p>
  </div>
  <div [style.margin-top.px]="40">
    <span class="fs-base-u5 fw-semibold fs-lg-u4 fw-lg-bold me-l12">{{ tab.value }}</span>
    <br class="d-md-none">
    <span class="fs-base-l2">{{ tab.unit?.[currentLang] }}</span>
  </div>
</ng-template>