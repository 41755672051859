import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { DeviceType } from '@shared/models/common.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  breakpoints = {
    sm: 576,
    md: 767,
    lg: 992,
    xl: 1320,
    xxl: 1984,
  };

  private breakpointObserver = inject(BreakpointObserver);

  constructor() {}

  getUnderBreakpointObserver(): Observable<{
    [P in keyof typeof this.breakpoints]: boolean;
  }> {
    const breakpoints = this.breakpointSizeNames.map((key) => {
      return `(max-width: ${this.breakpoints[key]}px)`;
    });
    return this.breakpointObserver.observe(breakpoints).pipe(
      map((res) => {
        const keys = Object.keys(
          res.breakpoints,
        ) as (keyof typeof this.breakpoints)[];
        return keys.reduce((obj, key) => {
          const maxWidth = key.match(/max-width:\s(\d+)/)?.[1];
          if (!maxWidth) {
            return obj;
          }
          const matchedBreakpoint = Object.entries(
            this.breakpoints,
          ).find((breakpoint) => {
            return breakpoint[1].toString() == maxWidth;
          });
          if (!matchedBreakpoint) {
            return obj;
          }
          const sizeName =
            matchedBreakpoint[0] as keyof typeof this.breakpoints;
          obj[sizeName] = res.breakpoints[key];
          return obj;
        }, {} as { [P in keyof typeof this.breakpoints]: boolean });
      }),
    );
  }

  getBreakpointDeviceObserver(): Observable<DeviceType> {
    return this.getUnderBreakpointObserver().pipe(
      map((res) => {
        if (res.md) {
          return 'mobile';
        } else if (res.lg) {
          return 'tablet';
        } else {
          return 'desktop';
        }
      }),
    );
  }

  get breakpointSizeNames(): (keyof typeof this.breakpoints)[] {
    return Object.keys(
      this.breakpoints,
    ) as (keyof typeof this.breakpoints)[];
  }
}
