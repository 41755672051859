import {
  Action,
  createPropertySelectors,
  State,
  StateContext,
} from '@ngxs/store';
import { MemoPreview } from './memo-preview.model';
import { Injectable } from '@angular/core';
import {
  CloseMemoPreview,
  NavigateToMemoDetail,
  OpenMemoPreview,
} from './memo-preview.actions';
import { Router } from '@angular/router';
import { MemoService } from '../../modules/memos/service/memo.service';
import { AuthStateModel } from '@store/auth/auth.model';
import { AuthState } from '@store/auth/auth.state';

@State<MemoPreview>({
  name: 'memoPreview',
  defaults: {
    isPreview: false,
    pdfFile: null,
    memo: null,
    activeTab: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class MemoPreviewState {
  constructor(
    private router: Router,
    private memoService: MemoService,
  ) {}

  @Action(OpenMemoPreview)
  openMemoPreview(
    ctx: StateContext<MemoPreview>,
    action: OpenMemoPreview,
  ) {
    ctx.patchState({
      ...action.payload,
      isPreview: true,
    });
  }

  @Action(CloseMemoPreview)
  closeMemoPreview(ctx: StateContext<MemoPreview>) {
    ctx.patchState({
      isPreview: false,
      pdfFile: null,
      memo: null,
      activeTab: null,
    });
  }

  @Action(NavigateToMemoDetail)
  navigateToMemoDetail(
    ctx: StateContext<MemoPreview>,
    { activeTab }: any,
  ) {
    const currentState = ctx.getState();
    const memoId = currentState.memo?.id;
    const navigationPath = activeTab
      ? ['memos', 'preview', memoId, activeTab]
      : ['memos', 'preview', memoId];
    const password = this.memoService.getPassword();
    this.router
      .navigate(navigationPath, {
        state: { backPath: this.router.url },
      })
      .then(() => {
        this.memoService.setPassword(password);
      });
    ctx.patchState({
      isPreview: false,
      pdfFile: null,
      memo: null,
      activeTab: activeTab,
    });
  }
}

export class MemoPreviewSelectors {
  static getSlices =
    createPropertySelectors<MemoPreview>(MemoPreviewState);
}
