import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  asyncScheduler,
  debounceTime,
  delay,
  fromEvent,
  interval,
  Subject,
  Subscription,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { ThemeService } from '@shared/service/theme.service';
import { TranslateService } from '@ngx-translate/core';
import {
  GroundColor,
  LanguageDisplay,
} from '@shared/models/common.model';

export interface Tab {
  color: GroundColor | undefined;
  iconPath: string;
  title: string;
  value: string;
  unit: LanguageDisplay<string>;
}

@Component({
  selector: 'app-saved-dashboard-slider',
  templateUrl: './saved-dashboard-slider.component.html',
  styleUrls: [
    '../../../../shared/components/slide-tab-bar/slide-tab-bar.component.scss',
    './saved-dashboard-slider.component.scss',
  ],
})
export class SavedDashboardSliderComponent
  implements AfterViewInit, OnDestroy
{
  @Input() tabs: Tab[] = [];

  @ViewChild('tabWrapper') set onTabWrapperChange(
    elRef: ElementRef<HTMLDivElement>,
  ) {
    this.tabWrapperElRef = elRef;
  }

  isDesktop = false;
  isResizing = false;
  navigateReleaseTakeUntil$: Subject<void> = new Subject();
  navigateHoldInterval$ = interval(20).pipe(
    delay(100),
    takeUntil(this.navigateReleaseTakeUntil$),
  );
  tabItemWidths: number[] = [];
  tabWrapperElRef?: ElementRef<HTMLDivElement>;

  private cdRef = inject(ChangeDetectorRef);
  private compSubs?: Subscription;
  private hostObserver?: ResizeObserver;
  private tabRowObserver?: ResizeObserver;
  private themeService = inject(ThemeService);

  constructor(
    private elRef: ElementRef,
    private translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    if (this.tabWrapperElRef) {
      const listener = fromEvent(
        this.tabWrapperElRef.nativeElement,
        'scroll',
      )
        .pipe(debounceTime(70))
        .subscribe({
          next: () => {
            this.cdRef.detectChanges();
          },
        });
      this.compSubs?.add(listener);
    }
    this.subscribeHostResize();
    asyncScheduler.schedule(() => {
      this.tabItemWidths = this.getTabItemsWidth();
      this.cdRef.detectChanges();
    });
  }

  getTabItemsWidth(): number[] {
    const flexGapPx = 20;
    const tabItemElRefs = this.tabRowElRef?.children;
    if (!tabItemElRefs) {
      return [];
    }
    const widths: number[] = [];
    for (let i = 0; i < tabItemElRefs.length; i++) {
      const tabItemElRef = tabItemElRefs.item(i);
      widths.push((tabItemElRef?.clientWidth || 0) + flexGapPx);
    }
    return widths;
  }

  subscribeHostResize(): void {
    if (!this.elRef) {
      return;
    }
    this.hostObserver = new ResizeObserver(() => this.onResize());
    this.hostObserver.observe(this.elRef.nativeElement);
    if (this.tabRowElRef) {
      let widthTemp = 0;
      this.tabRowObserver = new ResizeObserver((entry) => {
        const targetWidth = entry[0].contentRect.width;
        // This condition is set to prevent imprecise rendering
        const diff = Math.abs(targetWidth - widthTemp) >= 2;
        widthTemp = targetWidth;
        if (diff) {
          this.onResize();
        }
      });
      this.tabRowObserver.observe(this.tabRowElRef);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isDesktop = !this.themeService.isTabletOrMobile();
    this.isResizing = true;
    this.tabItemWidths = this.getTabItemsWidth();
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.isResizing = false;
      this.tabItemWidths = this.getTabItemsWidth();
      this.cdRef.detectChanges();
    }, 0); // The duration refer by css transition.
  }

  onNavigateHold(dir: 'left' | 'right'): void {
    const elRef = this.tabWrapperElRef?.nativeElement;
    if (!elRef) {
      return;
    }
    this.navigateHoldInterval$
      .pipe(
        takeWhile(() => {
          const scrollLeft = elRef?.scrollLeft || 0;
          const scrollWidth = elRef?.scrollWidth || 0;
          return dir === 'left'
            ? scrollLeft > 0
            : scrollLeft < scrollWidth;
        }),
      )
      .subscribe({
        next: () => {
          elRef.scrollTo({
            left: elRef.scrollLeft + (dir === 'left' ? -50 : 50),
            behavior: 'smooth',
          });
        },
      });
  }

  onNavigateRelease(): void {
    this.navigateReleaseTakeUntil$.next();
  }

  onNextClick(): void {
    const elRef = this.tabWrapperElRef?.nativeElement;
    const clientWidth = elRef?.clientWidth || 0;
    const scrollLeft = elRef?.scrollLeft || 0;
    const scrollRight = scrollLeft + clientWidth;
    let accWidth = 0;
    for (const width of this.tabItemWidths) {
      accWidth += width;
      if (accWidth > scrollRight) {
        break;
      }
    }
    elRef?.scrollTo({
      left: scrollLeft + accWidth - scrollRight,
      behavior: 'smooth',
    });
  }

  onPreviousClick(): void {
    const elRef = this.tabWrapperElRef?.nativeElement;
    const scrollLeft = elRef?.scrollLeft || 0;
    let accWidth = 0;
    let lastWidth = 0;
    for (const width of this.tabItemWidths) {
      lastWidth = width;
      accWidth += width;
      if (accWidth > scrollLeft) {
        break;
      }
    }
    let toLeft = scrollLeft - (lastWidth - (accWidth - scrollLeft));
    if (toLeft === scrollLeft) {
      toLeft = scrollLeft - lastWidth;
    }
    elRef?.scrollTo({
      left: toLeft,
      behavior: 'smooth',
    });
  }

  ngOnDestroy(): void {
    this.compSubs?.unsubscribe();
    this.hostObserver?.disconnect();
    this.tabRowObserver?.disconnect();
  }

  get tabRowElRef(): Element | undefined {
    return this.tabWrapperElRef?.nativeElement.getElementsByClassName(
      'tab-row',
    )[0];
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
