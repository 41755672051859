import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  HideLoaderAction,
  ShowLoaderAction,
} from './../../store/loader/load.actioins';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(
    private ngxSpinner: NgxSpinnerService,
    private store: Store,
  ) {}

  show(): void {
    this.store.dispatch(new ShowLoaderAction());
    this.ngxSpinner.show();
  }

  hide(): void {
    this.store.dispatch(new HideLoaderAction());
    this.ngxSpinner.hide();
  }

  showSpinner(active?: boolean, name = '', conf?: SpinnerConf): void {
    active ? this.ngxSpinner.show() : this.ngxSpinner.hide();
  }
}

export interface SpinnerConf {
  type: string;
  size: 'small' | 'medium' | 'large' | 'default';
  bdColor: 'rgba(0, 0, 0, 1)';
  color: string;
  template: string;
}
