<div class="container">
  <div class="content-frame">
    <div class="content custom-position"
        [ngStyle]="{'right': isAckDetail? '42%': '50%'}">
      <div class="flex-container text-center">
        <img src="assets/images/userPermission.png">
      </div>
      <div class="text-center">
        <div class="caption fontSize mt-4">{{ 'PERMISSION.Sorry you dont have permission' | translate }}</div>
        <p class="caption mt-3">{{ 'PERMISSION.This document is set to privacy settings'|translate }}</p>
      </div>
    </div>
  </div>
</div>