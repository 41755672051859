import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NavbarService } from '../navbar/navbar.service';
import { SpinnerService } from '../../services/spinner.service';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { featureFlag } from 'src/environments/environment';
import {
  ADFS_URL,
  LARK_AUTH_URL,
  THAID_URL,
} from '../../http/api.constant';
import { Store } from '@ngxs/store';
import { ClearState, Login } from 'src/app/store/auth/auth.actions';
import { versionInfo } from '../../../../version-info';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() forgetPassword = new EventEmitter();

  loginAdfsEnable = featureFlag.login_adfs_enable;
  loginForm: UntypedFormGroup;
  adfsForm: UntypedFormGroup;
  mode = this.loginAdfsEnable ? 1 : 0; // 0 : normal, 1 : ADFS

  version: any = versionInfo.hash;

  @ViewChild('wrongPasswordAttempt', { static: false })
  wrongPasswordAttempt: ElementRef;
  remainingAttempts: number | null;
  loginFailureLimit: number | null;

  subscription: Subscription[] = [];
  errors: { [key: string]: string } = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private spinner: SpinnerService,
    private navbar: NavbarService,
    private alert: AlertService,
    private translate: TranslateService,
    private store: Store,
    public modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ClearState);
    this.navbar.setActiveSidebar(false);
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          this.backendErrorValidation('username'),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          this.remainingAttemptsErrorValidation(),
        ],
      ],
    });
    this.adfsForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  ngAfterViewInit(): void {
    // Auto login if found that website open inside Lark.
    // I found that window.h5sdk exists only if user open this website in Lark app only.
    //   I think somehow library can detect if user open website on Lark app or not.
    const myWindow: any = window;
    if (myWindow.h5sdk) {
      this.authenticationService.loadLarkSDK().then(() => {
        console.log('request auth code', featureFlag.lark_app_id);
        myWindow.tt.requestAuthCode({
          appId: featureFlag.lark_app_id,
          success: (res: any) => {
            this.spinner.show();
            setTimeout(() => {
              window.location.href =
                LARK_AUTH_URL.code_auth + '?code=' + res.code;
            }, 100);
          },
          fail: (err: any) => {
            console.error(`Lark login failed ${JSON.stringify(err)}`);
            this.alert.error(
              `Lark login failed ${JSON.stringify(err)}`,
            );
          },
        });
      });
    }
  }

  validate() {
    this.f.username.updateValueAndValidity();
    this.f.password.updateValueAndValidity();
  }

  remainingAttemptsErrorValidation(): ValidatorFn {
    return (
      control: AbstractControl,
    ): { [key: string]: boolean } | null => {
      return this.remainingAttempts && this.remainingAttempts !== -999
        ? { remain_attempts: true }
        : null;
    };
  }

  backendErrorValidation(key: string): ValidatorFn {
    return (
      control: AbstractControl,
    ): { [key: string]: string } | null => {
      return this.errors[key] ? { be_error: this.errors[key] } : null;
    };
  }

  switchMode() {
    this.loginForm.reset();
    this.adfsForm.reset();
    this.mode = (this.mode + 1) % 2;
  }

  login(): void {
    this.errors = {};
    this.remainingAttempts = null;
    this.validate();
    if (this.loginForm.invalid) {
      return;
    }
    const subscription = this.store
      .dispatch(
        new Login({
          username: this.f.username.value,
          password: this.f.password.value,
        }),
      )
      .subscribe(
        () => {},
        (error) => {
          if (error.status === 400) {
            this.errors = error.error;
            this.validate();
            this.alert.error(
              this.translate.instant(
                'LOGIN.INVALID-USERNAME-OR-PASSWORD',
              ),
            );
          } else {
            this.handleLoginFailed(error);
          }
        },
      );
    this.subscription.push(subscription);
  }

  adfsLogin(): void {
    if (this.adfsForm.invalid) {
      return;
    }
    window.location.href =
      ADFS_URL.login +
      '?email=' +
      this.adfsForm.controls['email'].value;
  }

  loginThaiD() {
    window.location.href = THAID_URL.login;
  }

  handleLoginFailed(error: any) {
    if (error?.error?.detail == 'disabled_account') {
      this.remainingAttempts = -999;
    } else if (error?.error?.remaining_attempts) {
      this.remainingAttempts = error?.error?.remaining_attempts;
      this.loginFailureLimit = error?.error?.login_failure_limit;
    }
    if (this.remainingAttempts) {
      this.handleAttempts();
    } else {
      this.alert.error(
        error?.error?.detail ||
          this.translate.instant('ERROR.CONTACT-DEV'),
      );
    }
  }

  handleAttempts() {
    if (!this.remainingAttempts) {
      return;
    }
    if (
      this.remainingAttempts <= 0 &&
      this.remainingAttempts !== -999
    ) {
      this.router.navigate(['/account-locked']);
      return;
    }
    if (
      this.remainingAttempts <= 3 &&
      !this.modalService.hasOpenModals()
    ) {
      this.modalService.open(this.wrongPasswordAttempt, {
        centered: true,
        keyboard: false,
        size: 'size-smax56',
        backdrop: 'static',
      });
    } else if (this.remainingAttempts > 3) {
      this.alert.error(
        this.translate.instant('LOGIN.INVALID-USERNAME-OR-PASSWORD'),
      );
    }
    this.validate();
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  get f(): { [key: string]: AbstractControl } | any {
    return this.loginForm.controls;
  }

  get currentLang(): string {
    return this.translate.currentLang;
  }
}
