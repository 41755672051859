import { environment } from '../../../environments/environment';

const baseUrl = environment.baseUrl + '/api';

export const apiUrl = {
  login: baseUrl + '/token-auth/',
  users: baseUrl + '/profiles/',
};

export const ADFS_URL = {
  login: environment.baseUrl + '/api/oauth2/login/',
};

export const LARK_AUTH_URL = {
  code_auth: environment.baseUrl + '/api/oauth2/lark-callback/',
};

export const THAID_URL = {
  login: environment.baseUrl + '/api/oauth2/thaid-login/',
};
