import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../core/http/api.service';
import { API_URL } from './api.constant';
import { MemoService } from '../../modules/memos/service/memo.service';
import { RESPONSE$ } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  memoNotificationCount = 0;

  constructor(
    private router: Router,
    private http: ApiService,
    private memoService: MemoService,
  ) {}

  updateNotificationCount() {
    this.memoService.getMyTaskBadge().subscribe((memos: any) => {
      this.memoNotificationCount = memos.count;
    });
  }

  // Feature Notifications

  getAllNotification(params?: any): RESPONSE$ {
    return this.http.get(API_URL.notification, params);
  }

  markAllAsRead() {
    return this.http.post(API_URL.notification + 'read-all/', null);
  }

  updateIsRead(id: any) {
    return this.http.post(
      API_URL.notification + id + '/' + 'read' + '/',
      id,
    );
  }
}

export interface MutedNotification {
  id?: number;
  disabled?: boolean;
  channel: string;
  action: string;
}

export interface NotificationList {
  group: string;
  actions: Actions[];
  tooltipEN?: string;
  tooltipTH?: string;
}

export interface Actions {
  label: string;
  value: string;
}

export interface NotificationChannel {
  name: string;
  label: string;
}

export interface newNotificationAction {
  label: string;
  value: string;
  tooltipTH?: string;
  tooltipEN?: string;
}
