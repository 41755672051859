import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-memo-no-permission',
  templateUrl: './memo-no-permission.component.html',
  styleUrls: ['./memo-no-permission.component.scss'],
})
export class MemoNoPermissionComponent {
  @Input() iconType: string;
  @Input() iconPath: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() goBackButton = false;
  @Output() clickGoBackButton = new EventEmitter();

  onClick() {
    this.clickGoBackButton.emit();
  }
}
