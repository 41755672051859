export class ShowAnnouncementDetail {
  static readonly type = '[Announcement] ShowAnnouncementDetail';
  constructor(public id: number, public viaLink?: boolean) {}
}

export class ClearSelectAnnouncement {
  static readonly type = '[Announcement] ClearSelectAnnouncement';
  constructor() {}
}

export class AnnouncementToggleMenu {
  static readonly type = '[Announcement] ToggleMenu';
  constructor() {}
}

export class ClearAnnouncementState {
  static readonly type = '[Announcement] ClearAnnouncementState';
  constructor() {}
}

export class EmptyContentAnnouncement {
  static readonly type = '[Announcement] EmptyContent';
  constructor(public emptyContent?: boolean) {}
}
