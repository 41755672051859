<div class="d-flex align-items-center position-relative">
  <input [type]="show ? 'text': 'password'"
      [ngClass]="{'is-invalid': invalid }"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      (ngModelChange)="onValueChange($event)"
      class="form-control"
      autocomplete="off"/>
  <div class="position-absolute"
      [style.right.px]="invalid ? 30 : 10"
      (click)="show = !show">
    <svg class="pointer text-gray-neutrals"
        height="21"
        stroke="currentColor"
        viewBox="0 0 24 24"
        width="21"
        stroke-width="1.5">
      <style>
          .eye-lid {
              stroke: #707485;
          }

          .eye-iris {
              stroke: #55596d
          }
      </style>
      <use [attr.href]="show ?
                'assets/images/icons/body/eye-half.svg#eye-half' :
                'assets/images/icons/body/eye-close.svg#eye-close'"
          height="24"
          width="24">
      </use>
    </svg>
  </div>
</div>