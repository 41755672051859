import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private translate: TranslateService) {}

  successPublish(
    title: string = this.translate.instant('ALERT.Save successful'),
    timer = 8000,
  ): void {
    Swal.fire({
      toast: true,
      position: 'top-right',
      iconHtml: `<svg class="icon-btn-svg-alert">
                    <use href="assets/images/pictograms/paper-plane-circle-fill.svg#paper-plane-circle-fill"
                        height="79"
                        width="77">
                    </use>
                </svg>`,
      title: title || 'Success',
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: 'bg-border-publish',
      },
      buttonsStyling: true,
      timer: timer || 8000,
    });
  }

  success(
    title: string = this.translate.instant('ALERT.Save successful'),
    timer = 8000,
  ): void {
    Swal.fire({
      toast: true,
      position: 'top-right',
      iconHtml: `<svg class="icon-btn-svg-alert">
                    <use href="assets/images/pictograms/check-circle-fill.svg#check-circle-fill"
                        height="81"
                        width="79">
                    </use>
                </svg>`,
      title: title || 'Success',
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: 'bg-border-success',
      },
      buttonsStyling: true,
      timer: timer || 8000,
    });
  }

  errorSubText(
    title: string,
    subtext: string,
    icon = {
      iconPath:
        'assets/images/pictograms/warning-circle-fill.svg#warning-circle-fill',
      width: 78,
      height: 79,
      x: 0,
      y: 0,
    },
    timer = 8000,
  ): void {
    const svgIcon = `
      <svg class="icon-btn-svg-alert" 
          stroke="#fff">
        <use href="${icon.iconPath}" 
            width="${icon.width}" 
            height="${icon.height}"
            x="${icon.x}"
            y="${icon.y}">
        </use>
      </svg>`;
    Swal.fire({
      toast: true,
      position: 'top-end',
      iconHtml: svgIcon,
      title: title,
      text: subtext,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        popup: 'bg-border-error-sub-text',
      },
      timer: timer,
    });
  }

  error(title = 'Error', timer = 8000): void {
    Swal.fire({
      toast: true,
      position: 'top-right',
      iconHtml: `<svg class="icon-btn-svg-alert">
                    <use href="assets/images/pictograms/warning-circle-fill.svg#warning-circle-fill"
                        height="79"
                        width="78">
                    </use>
                </svg>`,
      showConfirmButton: false,
      cancelButtonText: '<i class="fas fa-times"></i>',
      title: title || 'Error',
      showCloseButton: true,
      customClass: {
        popup: 'bg-border-error',
      },
      buttonsStyling: true,
      timer: timer || 8000,
    });
  }

  errorDetail(title: string | HTMLElement | JQuery, html: any): void {
    Swal.fire({
      icon: 'error',
      title,
      showConfirmButton: true,
      html,
    });
  }

  confirm(
    title: string = this.translate.instant('ALERT.Are you sure'),
    text: string = this.translate.instant(
      'ALERT.You want to save your changes',
    ),
    icon: SweetAlertIcon = 'warning',
    confirmButtonText: string = this.translate.instant(
      'ALERT.Confirm',
    ),
    showCancelButton = true,
    cancelButtonText: string = this.translate.instant('ALERT.Cancel'),
  ): Promise<
    SweetAlertResult<{
      isConfirmed: boolean;
      isDismissed: boolean;
      value: boolean;
    }>
  > {
    return Swal.fire({
      title,
      text,
      icon,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      confirmButtonColor: '#3A3A3A',
      cancelButtonColor: '#fff#00000029',
    });
  }

  permission(): Promise<SweetAlertResult<unknown>> {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: ' No permission to access!',
    });
  }

  warning(title = 'Warning', timer = 2000) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      iconColor: '#ffc107',
      icon: 'warning',
      title: title || 'Warning',
      showConfirmButton: false,
      timer: timer || 2000,
    });
  }
}
