import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { NavbarService } from '@core/components/navbar/navbar.service';
import { GetUserInfo } from '../../../store/auth/auth.actions';
import { Store } from '@ngxs/store';
import { ErrorNotification } from '../../../store/memo/memo.actions';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnDestroy {
  role: string;
  isResettingPassword: boolean;

  errors: { [key: string]: string } = {};
  isLoading = false;

  subscription: Subscription[] = [];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private navbarService: NavbarService,
    private store: Store,
  ) {
    if (!this.authenticationService.currentUserValue) {
      this.goToLogin();
    } else {
      this.store.dispatch(GetUserInfo).subscribe(
        () => {
          this.role = this.authenticationService.role;
          this.isResettingPassword =
            this.authenticationService.isResettingPassword;
          if (this.authenticationService.isPasswordValid) {
            this.go();
          }
        },
        (error) => {
          this.store.dispatch(new ErrorNotification(error));
          this.goToLogin();
        },
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    });
  }

  send(password: string) {
    this.isLoading = true;
    const sub = this.authenticationService
      .setExpiredPassword({
        password: password,
      })
      .subscribe(
        () => {
          this.store.dispatch(GetUserInfo).subscribe(
            () => {
              this.isLoading = false;
              this.go();
            },
            (error) => {
              this.isLoading = false;
              this.store.dispatch(new ErrorNotification(error));
            },
          );
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 400) {
            this.errors = error.error;
          } else {
            this.store.dispatch(new ErrorNotification(error));
          }
        },
      );
    this.subscription.push(sub);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  go() {
    const redirectPage = this.isGeneral ? '/memos' : '/users';
    this.router
      .navigate([redirectPage])
      .then(() => this.navbarService.setActiveSidebar(true));
  }

  get isGeneral(): boolean {
    return this.role.toLowerCase() === 'general';
  }
}
