import { NgModule } from '@angular/core';
import { SavedDashboardComponent } from '@core/components/dashboard-container/saved-dashboard/saved-dashboard.component';
import { CommonModule } from '@angular/common';
import { SavedDashboardSliderComponent } from './saved-dashboard-slider/saved-dashboard-slider.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SavedDashboardComponent,
    SavedDashboardSliderComponent,
  ],
  imports: [CommonModule, TranslateModule],
  exports: [SavedDashboardComponent],
  providers: [],
})
export class SavedDashboardModule {}
