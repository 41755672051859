<form #fg="ngForm"
    [formGroup]="form"
    (submit)="send()"
    class="row">
  <div class="col-12">
    <label class="mt-base-u8 mt-base-lg-u0">
      {{ "PROFILE.NEW-PASSWORD"|translate }}<span class="text-asterisk">*</span>
    </label>
    <app-password-input
        [ngClass]="{'is-invalid': (fg.submitted && f.password.invalid) || f.password.errors?.be_error}"
        formControlName="password"
        [placeholder]="'PROFILE.NEW-PASSWORD'|translate"
        [showInvalid]="fg.submitted"
        (click)="showPasswordRuleBox=true"
        (ngModelChange)="f.confirm_password.updateValueAndValidity()">
    </app-password-input>
    <div *ngIf="f.password.errors?.be_error"
        class="invalid-feedback">
      {{ f.password.errors.be_error[currentLang] }}
    </div>
    <app-password-rule-box
        *ngIf="passwordPolicy && (showPasswordRuleBox || f.password.value || (fg.submitted && f.password.invalid))"
        [passwordPolicy]="passwordPolicy"
        [password]="f.password.value"
        (validPasswordChange)="setValidPassword($event)">
    </app-password-rule-box>
  </div>
  <div class="col-12">
    <label [ngClass]="{'mt-base-u8 mt-base-lg-u0': !(passwordPolicy
    && (showPasswordRuleBox || f.password.value || (fg.submitted && f.password.invalid)))}">
      {{ "PROFILE.CONFIRM-PASSWORD"|translate }}<span class="text-asterisk">*</span>
    </label>
    <app-password-input
        [showInvalid]="fg.submitted"
        [ngClass]="{'is-invalid': fg.submitted && f.confirm_password.invalid }"
        formControlName="confirm_password"
        [placeholder]="'PROFILE.CONFIRM-PASSWORD'|translate">
    </app-password-input>
    <div *ngIf="fg.submitted && f.confirm_password.invalid"
        class="invalid-feedback">
      {{ 'PROFILE.CONFIRM-PASS-NOT-MATCH' | translate }}
    </div>
  </div>
  <div class="mt-base-u8 mt-base-lg-u0 d-flex justify-content-end">
    <button class="btn btn-std btn-std-prim w-100"
        [ladda]="isLoading"
        title="{{ 'MEMOS.CONFIRM' | translate }}"
        type="submit">
      <span>{{ 'MEMOS.CONFIRM' | translate }}</span>
    </button>
  </div>
</form>