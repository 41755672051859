import { LoginPayload } from './auth.model';

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: LoginPayload) {}
}

export class GetUserInfo {
  static readonly type = '[Auth] Get User Info';
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class ClearState {
  static readonly type = '[Auth] Clear State';
}

export class SaveUserDetail {
  static readonly type = '[Auth] SaveUserDetail';
  constructor(public value: any, public name: string) {}
}

export class SaveToken {
  static readonly type = '[Auth] Save token';
  constructor(public token: string) {}
}
