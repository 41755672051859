import { OpenMemoPreviewPayload } from './memo-preview.model';

export class OpenMemoPreview {
  static readonly type = '[Memo preview] OpenMemoPreview';

  constructor(public payload: OpenMemoPreviewPayload) {}
}

export class CloseMemoPreview {
  static readonly type = '[Memo preview] CloseMemoPreview';
}

export class NavigateToMemoDetail {
  static readonly type = '[Memo preview] NavigateToMemoDetail';

  constructor(public activeTab?: string) {}
}
