import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { ApiParams, RESPONSE$ } from 'src/app/shared/utils/constants';
import {
  Announcement,
  AnnouncementRESPONSE$,
  NewsFeedComment,
  NewsFeedPost,
} from './news-feed.models';

@Injectable({
  providedIn: 'root',
})
export class NewsFeedService {
  public checkCloseMenageAnnounce = false;

  constructor(private http: ApiService) {}

  // Comment
  getNewsFeedComment(): RESPONSE$<NewsFeedComment> {
    return this.http.get('/api/news-feed/comments/');
  }

  createNewsFeedComment(
    comment: ApiParams,
  ): Observable<NewsFeedComment> {
    return this.http.post('/api/news-feed/comments/', comment);
  }

  deleteNewsFeedComment(id: number): Observable<unknown> {
    return this.http.delete(`/api/news-feed/comments/${id}/`);
  }

  // News feed post
  getAllPost(params?: ApiParams): RESPONSE$<NewsFeedPost> {
    return this.http.get('/api/news-feed-post/', params);
  }

  getRelatedPost(params?: ApiParams): RESPONSE$<NewsFeedPost> {
    return this.http.get('/api/news-feed-post/related-post/', params);
  }

  createFeedPost(post: ApiParams): Observable<NewsFeedPost> {
    return this.http.post('/api/news-feed-post/', post);
  }

  updateFeedPost(
    id: number,
    data: ApiParams,
  ): Observable<NewsFeedPost> {
    return this.http.patch(
      `/api/news-feed-post/related-post/${id}/`,
      data,
    );
  }

  deleteFeedPost(id: number): Observable<unknown> {
    return this.http.delete(`/api/news-feed-post/${id}/`);
  }

  /// Announcement
  getAnnouncementList(
    params?: ApiParams,
  ): AnnouncementRESPONSE$<Announcement> {
    return this.http.get('/api/announcement/', params);
  }

  getAnnouncementDetail(id: number): Observable<Announcement> {
    return this.http.get(`/api/announcement/${id}/`);
  }

  deleteAnnouncementList(id: number): Observable<unknown> {
    return this.http.delete(`/api/announcement/${id}/`);
  }

  hiddenAnnouncement(
    id: number,
    data?: ApiParams,
  ): Observable<unknown> {
    return this.http.post(
      `/api/announcement/${id}/hide_announcement/`,
      data,
    );
  }

  unhideAnnouncement(
    id: number,
    data?: ApiParams,
  ): Observable<unknown> {
    return this.http.post(
      `/api/announcement/${id}/unhide_announcement/`,
      data,
    );
  }

  ack(id: any, data: any) {
    return this.http.post(`/api/acknowledges/${id}/ack/`, data);
  }

  downloadMemo(id: number): Observable<Blob> {
    return this.http.postResponseBlob(
      `/api/announcement/${id}/download-pdf/`,
      {},
    );
  }

  getPreviewPDF(data?: ApiParams) {
    return this.http.pdfPost(
      '/api/news-feed/comments/attachment/',
      data,
    );
  }
}
