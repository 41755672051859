import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
  HeaderDetailDirective,
  TextSignatureComponent,
} from './text-signature.component';
import { SlideTabBarComponent } from '@shared/components/slide-tab-bar/slide-tab-bar.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LaddaModule } from 'angular2-ladda';

@NgModule({
  declarations: [TextSignatureComponent],
  exports: [TextSignatureComponent, HeaderDetailDirective],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    SlideTabBarComponent,
    HeaderDetailDirective,
    AngularSvgIconModule,
    LaddaModule,
  ],
})
export class TextSignatureModule {}
