import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetUserInfo } from 'src/app/store/auth/auth.actions';
import { AuthenticationService } from '@core/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private authenticationService: AuthenticationService) {}
  checkAccess(roles: string[]) {
    const role = this.authenticationService.role;
    return roles.includes(role);
  }
}
