import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = environment.baseUrl;

  constructor(
    protected http: HttpClient,
    protected translate: TranslateService,
  ) {}

  requestHeader(language?: string): HttpHeaders {
    const lang =
      language ||
      this.translate.currentLang ||
      localStorage.getItem('lang');
    const headers = new HttpHeaders().set(
      'Accept-Language',
      lang || 'en',
    );
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    return headers;
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        if (typeof reader.result === 'string') {
          observer.error(JSON.parse(reader.result));
        }
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  get<T>(url: string, params?: Params, headers?: any): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {
      headers: headers ? headers : this.requestHeader(),
      params: { ...params },
    });
  }

  getBlob<T>(
    url: string,
    params?: any,
    headers?: HttpHeaders,
  ): Observable<T> {
    let fullUrl = '';
    if (url.startsWith('http://') || url.startsWith('https://')) {
      fullUrl = url;
    } else {
      fullUrl = this.baseUrl + url;
    }
    return this.http
      .get<T>(fullUrl, {
        headers: headers ?? this.requestHeader(),
        params: { ...params },
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.parseErrorBlob));
  }

  getMedia<T>(url: string, params?: any): Observable<T> {
    return this.http
      .get<T>(url, {
        headers: this.requestHeader(),
        params: { ...params },
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.parseErrorBlob));
  }

  printFile<T>(url: any, params?: any): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  post<T>(url: string, item: any, headers?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, item, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  put<T>(url: string, item: any): Observable<T> {
    return this.http.put<T>(this.baseUrl + url, item, {
      headers: this.requestHeader(),
    });
  }

  patch<T>(url: string, item: any, headers?: any): Observable<T> {
    return this.http.patch<T>(this.baseUrl + url, item, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  delete<T>(url: string, item?: any, headers?: any): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, {
      headers: headers ? headers : this.requestHeader(),
    });
  }

  download<T>(
    url: string,
    params?: { [type: string]: string },
  ): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.requestHeader(),
      params: { ...params },
      responseType: 'blob' as 'json',
    });
  }

  pdfPost<T>(url: string, data?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, data, {
      headers: this.requestHeader(),
    });
  }

  postResponseBlob<T>(
    url: string,
    data?: any,
    headers?: HttpHeaders,
  ): Observable<T> {
    return this.http
      .post<T>(this.baseUrl + url, data, {
        headers: headers ? headers : this.requestHeader(),
        responseType: 'blob' as 'json',
      })
      .pipe(catchError(this.parseErrorBlob));
  }

  // Check Mobile Operating system
  getMobileOperatingSystem(): string | any {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/.test(userAgent)) {
      return 'Android';
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return null;
  }

  isMobileOperationSystem(): boolean {
    return this.getMobileOperatingSystem() != null;
  }

  openMediaUrlInNewWindow(mediaUrl: any) {
    if (mediaUrl == null) return;

    mediaUrl = mediaUrl.trim();
    if (mediaUrl.length === 0) return;

    // open new window here to prevent popup-block.
    // default page will be MediaWindowComponent in file 'app-routing.module.ts'
    // because mobile device cannot preview pdf and it will automatically download file.
    const newWindow: any = window.open('/window-media');

    this.getBlob(mediaUrl, { responseType: 'blob' }).subscribe(
      (response: Blob | any) => {
        // this will fix Adblock block new window.
        // more info: https://stackoverflow.com/questions/43283454/open-blob-objecturl-in-chrome

        const checkReadyState = () => {
          if (newWindow.document.readyState === 'complete') {
            newWindow.removeEventListener('load', checkReadyState);
            this.setWindowLocation(response, newWindow);
          }
        };
        newWindow.addEventListener('load', checkReadyState);
        newWindow.location = URL.createObjectURL(response);
      },
      (error: any) => {
        console.error('Failed to fetch media:', error);
      },
    );
  }

  setWindowLocation(response: Blob, newWindow: Window): void {
    const objectURL = URL.createObjectURL(response);

    // Navigate the new window to the object URL
    newWindow.location.href = objectURL;
    newWindow.focus();

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(objectURL);
  }
}
