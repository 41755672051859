import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'date',
  pure: false,
})
export class BuddhistDatePipe
  extends DatePipe
  implements PipeTransform
{
  constructor(private translate: TranslateService) {
    super(translate.currentLang);
  }

  override transform(value: any, format?: any): any {
    if (!value) {
      let dt = dayjs.isDayjs(value) ? value : dayjs(value);
      if (this.translate.currentLang === 'th') {
        dt = dt.add(543, 'years');
      }
      value = dt.toDate();
    }

    return super.transform(
      value,
      format,
      undefined,
      this.translate.currentLang,
    );
  }
}
